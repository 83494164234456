const XR8Module = ({ set, get }) => {
  return {
    XR8: null,
    setXR8: (e) => set({ XR8: e }),

    pipelineReady: false,
    setPipelineReady: (e) => set({ pipelineReady: e }),
  };
};
export { XR8Module };
