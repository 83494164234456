// React
import { memo, useRef, useEffect } from "react";

// Global State
import { useGlobalState } from "../../../state/useGlobalState";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a } from "@react-spring/web";

// Styled Components
import styled from "styled-components";

// Style
import { PageContainer } from "../../../styles/Containers";
import { MarginPaddingNone } from "../../../styles/Attributes";

const TouchPointInstruct = () => {
  // Refs
  const burstRef = useRef();
  const instructBoxRef = useRef();
  const touchpointDemoRef = useRef();
  const touchpointInstructRef = useRef();
  const touchpointDemoHandRef = useRef();
  const demoCounterRef = useRef(1);

  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);
  const isTouchpointInstruction = useGlobalState(
    (state) => state.isTouchpointInstruction
  );
  const setIsTouchpointInstruction = useGlobalState(
    (state) => state.setIsTouchpointInstruction
  );

  const handleCloseDemo = () => {
    gsap.to(instructBoxRef.current, {
      opacity: 0,
      ease: "power1.inOut",
      overwrite: true,
      onComplete: () => {
        setIsTouchpointInstruction(false);
        gsap.to(touchpointInstructRef.current, {
          display: "none",
          duration: 0.00001,
          overwrite: true,
        });
      },
    });
  };

  // Animations
  const [{ btnScale }, setBtnSpring] = useSpring(() => ({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    btnScale: 1,
  }));

  const tl = gsap.timeline();

  const runDemo = () => {
    tl.fromTo(
      touchpointDemoHandRef.current,
      {
        x: 0,
        y: 0,
      },
      {
        x: -50,
        y: -50,
        ease: "expo.inOut",
        duration: 1.3,
      }
    );
    tl.to(touchpointDemoHandRef.current, {
      scale: 0.8,
      ease: "power1.out",
      duration: 0.1,
      onComplete: () => {
        gsap.fromTo(
          burstRef.current,
          { scale: 0 },
          {
            scale: 12,
            ease: "power3.out",
            duration: 1.5,
          }
        );

        gsap.fromTo(
          burstRef.current,
          { opacity: 1 },
          {
            opacity: 0,
            delay: 0.3,
            ease: "power3.out",
            duration: 1,
          }
        );

        gsap.to(touchpointDemoRef.current, {
          scale: 0.8,
          ease: "power1.out",
          duration: 0.1,
          overwrite: true,
          onComplete: () => {
            gsap.to(touchpointDemoRef.current, {
              scale: 1,
              ease: "power1.out",
              duration: 0.1,
              overwrite: true,
            });
          },
        });
      },
    });
    tl.to(touchpointDemoHandRef.current, {
      scale: 1,
      ease: "power1.out",
      duration: 0.1,
    });

    tl.to(touchpointDemoHandRef.current, {
      x: 0,
      y: 0,
      ease: "expo.inOut",
      duration: 1.5,
      onComplete: () => {
        if (demoCounterRef.current >= 3) {
          handleCloseDemo();
        } else if (isTouchpointInstruction) {
          demoCounterRef.current += 1;
          runDemo();
        }
      },
    });
  };

  const scaleUpButton = () => {
    setBtnSpring.start({
      btnScale: 1,
    });
  };

  const handlePointerDown = (e) => {
    e.stopPropagation();
    setBtnSpring.start({
      btnScale: 0.85,
    });
  };

  const handlePointerUp = () => {
    scaleUpButton();
    handleCloseDemo();
  };

  const handleMount = () => {
    gsap.to(touchpointInstructRef.current, {
      display: "flex",
      duration: 0.00001,
      overwrite: true,
      onComplete: () => {
        gsap.to(instructBoxRef.current, {
          delay: 1.5,
          opacity: 1,
          ease: "power1.inOut",
          overwrite: true,
          onComplete: () => {
            runDemo();
          },
        });
      },
    });
  };

  useEffect(() => {
    if (/ARLaunch/gi.test(hudPhase) && isScenePlaced) {
      handleMount();
    }

    return () => {
      tl.kill();
      gsap.killTweensOf([
        burstRef.current,
        instructBoxRef.current,
        touchpointDemoRef.current,
        touchpointInstructRef.current,
        touchpointDemoHandRef.current,
      ]);
    };
  }, [hudPhase, isScenePlaced]);

  return (
    <TouchPointInstructContainer ref={touchpointInstructRef}>
      <div className="instruct-box" ref={instructBoxRef}>
        <div className="top-row-container">
          <a.button
            className="close-btn"
            style={{ scale: btnScale }}
            onPointerOut={scaleUpButton}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
          >
            <lottie-player
              src="/ui/3-ar_launch/close.json"
              mode="normal"
              loop="true"
              autoplay
            />
          </a.button>
        </div>

        <div className="touchpoint-instruct">
          <div className="burst" ref={burstRef} />
          <img
            ref={touchpointDemoRef}
            className="touchpoint-demo"
            draggable="false"
            src={`/ui/3-ar_launch/touchpoint_demo.svg`}
            alt={"touchpoint-demo"}
          />

          <img
            ref={touchpointDemoHandRef}
            className="touchpoint-demo-hand"
            draggable="false"
            src={`/ui/3-ar_launch/hand.svg`}
            alt={"touchpoint-demo-hand"}
          />
        </div>
        <h3 className="touchpoint-copy">
          Tap icons to <br />
          view more information
        </h3>
      </div>
    </TouchPointInstructContainer>
  );
};

export default memo(TouchPointInstruct);

const TouchPointInstructContainer = styled(PageContainer)`
  display: none;
  .instruct-box {
    ${MarginPaddingNone}
    opacity: 0;
    padding: 1rem;
    background-color: black;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(0.2rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-row-container {
      ${MarginPaddingNone}
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .close-btn {
        ${MarginPaddingNone}
        background: none;
        border: none;
        width: 1.4rem;
      }
    }
    .touchpoint-instruct {
      ${MarginPaddingNone}
      width: 10rem;
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
      .burst {
        ${MarginPaddingNone}
        width: 1rem;
        height: 1rem;
        background-color: white;
        border-radius: 2rem;
        position: relative;
      }

      .touchpoint-demo {
        ${MarginPaddingNone}
        width: 6.5rem;
        height: 6.5rem;
        position: absolute;
      }
      .touchpoint-demo-hand {
        ${MarginPaddingNone}
        width: 3.4rem;
        height: 3.4rem;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .touchpoint-copy {
      ${MarginPaddingNone}
      font-family: "GilroyRegular";
      font-size: 1.1rem;
      text-align: center;
    }
  }
`;
