// React
import { Fragment, useRef, useEffect, memo } from "react";

// State
import { useGlobalState } from "../../../state/useGlobalState";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a } from "@react-spring/web";

// Styled Components
import styled from "styled-components";

// Data
import { InfoBoxData } from "./InfoBoxData";

// Components
import InfoBox from "./InfoBox";
import TouchPointInstruct from "./TouchPointInstruct";

// Styles
import { PageContainer } from "../../../styles/Containers";
import { MarginPaddingNone } from "../../../styles/Attributes";

const ARLaunch = () => {
  // Refs
  const cloverRef = useRef();
  const toggleRef = useRef();
  const soundOnRef = useRef();
  const toggleBGRef = useRef();
  const soundOffRef = useRef();
  const retakeSaveRef = useRef();
  const captureButtonRef = useRef();
  const immersiveButtonRef = useRef();
  const soundButtonRef = useRef();
  const photoContainerRef = useRef();

  // Global State
  const XR8 = useGlobalState((state) => state.XR8);
  const photo = useGlobalState((state) => state.photo);
  const setPhoto = useGlobalState((state) => state.setPhoto);
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const setMuted = useGlobalState((state) => state.setMuted);
  const sharePhoto = useGlobalState((state) => state.sharePhoto);
  const isGlobalMute = useGlobalState((state) => state.isGlobalMute);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);
  const setIsGlobalMute = useGlobalState((state) => state.setIsGlobalMute);
  const HughsNetVOKeys = useGlobalState((state) => state.HughsNetVOKeys);
  const currentKey = useGlobalState((state) => state.currentKey);
  const setStopAudio = useGlobalState((state) => state.setStopAudio);
  const setHideInfoBox = useGlobalState((state) => state.setHideInfoBox);
  const isImmersive = useGlobalState((state) => state.isImmersive);
  const setIsImmersive = useGlobalState((state) => state.setIsImmersive);
  const setIsFading = useGlobalState((state) => state.setIsFading);
  const isTouchpointInstruction = useGlobalState(
    (state) => state.isTouchpointInstruction
  );
  const setCurrentlyPlaying = useGlobalState(
    (state) => state.setCurrentlyPlaying
  );

  const handlePhotoCapture = async () => {
    if (XR8) {
      try {
        const data = await XR8.CanvasScreenshot.takeScreenshot();
        await setPhoto(`data:image/jpeg;base64,${data}`);
        setHideInfoBox(true);
        gsap.to(captureButtonRef.current, {
          scale: 0,
          delay: 0.2,
          duration: 0.4,
          overwrite: true,
          ease: "power3.inOut",
        });
        gsap.to(retakeSaveRef.current, {
          y: 0,
          duration: 1,
          overwrite: true,
          ease: "power3.inOut",
        });
        gsap.to(photoContainerRef.current, {
          display: "flex",
          duration: 0.00001,
          overwrite: true,
          onComplete: () => {
            gsap.to(photoContainerRef.current, {
              opacity: 1,
              duration: 0.3,
              ease: "power3.inOut",
            });
          },
        });
        setStopAudio(currentKey);
        setCurrentlyPlaying(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePhotoRetake = () => {
    setHideInfoBox(false);
    setIsFading(false);
    gsap.to(photoContainerRef.current, {
      opacity: 0,
      duration: 0.5,
      overwrite: true,
      ease: "power3.inOut",
      onComplete: () => {
        gsap.to(photoContainerRef.current, {
          display: "none",
          duration: 0.00001,
        });
        setPhoto(null);
      },
    });
    gsap.to(captureButtonRef.current, {
      scale: 1,
      delay: 0.2,
      duration: 0.4,
      overwrite: true,
      ease: "power3.inOut",
    });
    gsap.to(retakeSaveRef.current, {
      y: 200,
      duration: 1,
      overwrite: true,
      ease: "power3.inOut",
    });
  };

  const handlePhotoSave = () => {
    gsap.to(retakeSaveRef.current, {
      duration: 0.0001,
      onComplete: () => {
        gsap.to(retakeSaveRef.current, {
          delay: 0.1,
          duration: 0.1,
          onComplete: () => {
            sharePhoto();
          },
        });
      },
    });
  };

  const handleImmersiveToggle = (e) => {
    e.stopPropagation();
    if (!isImmersive) {
      setIsImmersive(true);
      gsap.to(toggleRef.current, {
        x: 47,
        ease: "power3.inOut",
        overwrite: true,
      });
      gsap.to(toggleBGRef.current, {
        opacity: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
    } else {
      setIsImmersive(false);
      gsap.to(toggleRef.current, {
        x: 0,
        ease: "power3.inOut",
        overwrite: true,
      });
      gsap.to(toggleBGRef.current, {
        opacity: 0,
        ease: "power3.inOut",
        overwrite: true,
      });
    }
  };

  const handleSoundState = (e) => {
    e.stopPropagation();
    if (isGlobalMute) {
      setIsGlobalMute(false);
      HughsNetVOKeys.forEach((key) => {
        setMuted(key, false);
      });
      soundOnRef.current.style.visibility = "visible";
      soundOffRef.current.style.visibility = "hidden";
    } else {
      setIsGlobalMute(true);
      HughsNetVOKeys.forEach((key) => {
        setMuted(key, true);
      });
      soundOnRef.current.style.visibility = "hidden";
      soundOffRef.current.style.visibility = "visible";
    }
  };

  const springConfig = {
    mass: 1,
    tension: 300,
    friction: 12,
    precision: 0.001,
  };

  const [{ captureBtnScale }, setCaptureBtnSpring] = useSpring(() => ({
    config: springConfig,
    captureBtnScale: 1,
  }));

  const scaleUpCaptureButton = () => {
    setCaptureBtnSpring.start({
      captureBtnScale: 1,
    });
  };

  const handleCapturePointerDown = (e) => {
    e.stopPropagation();
    setCaptureBtnSpring.start({
      captureBtnScale: 0.85,
    });
  };

  const handleCapturePointerUp = () => {
    scaleUpCaptureButton();
    handlePhotoCapture();
  };

  const [{ retakeBtnScale }, setRetakeBtnSpring] = useSpring(() => ({
    config: springConfig,
    retakeBtnScale: 1,
  }));

  const scaleUpRetakeButton = () => {
    setRetakeBtnSpring.start({
      retakeBtnScale: 1,
    });
  };

  const handleRetakePointerDown = (e) => {
    e.stopPropagation();
    setRetakeBtnSpring.start({
      retakeBtnScale: 0.85,
    });
  };

  const handleRetakePointerUp = () => {
    scaleUpRetakeButton();
    handlePhotoRetake();
  };

  const [{ saveBtnScale }, setSaveBtnSpring] = useSpring(() => ({
    config: springConfig,
    saveBtnScale: 1,
  }));

  const scaleUpSaveButton = () => {
    setSaveBtnSpring.start({
      saveBtnScale: 1,
    });
  };

  const handleSavePointerDown = (e) => {
    e.stopPropagation();
    setSaveBtnSpring.start({
      saveBtnScale: 0.85,
    });
  };

  const handleSavePointerUp = () => {
    scaleUpSaveButton();
    handlePhotoSave();
  };

  const [{ immersiveBtnScale }, setImmersiveBtnSpring] = useSpring(() => ({
    config: springConfig,
    immersiveBtnScale: 1,
  }));

  const scaleUpImmersiveButton = () => {
    setImmersiveBtnSpring.start({
      immersiveBtnScale: 1,
    });
  };

  const handleImmersivePointerDown = (e) => {
    e.stopPropagation();
    setImmersiveBtnSpring.start({
      immersiveBtnScale: 0.85,
    });
  };

  const handleImmersivePointerUp = (e) => {
    scaleUpImmersiveButton();
    handleImmersiveToggle(e);
  };

  useEffect(() => {
    if (
      /ARLaunch/gi.test(hudPhase) &&
      isScenePlaced &&
      !isTouchpointInstruction
    ) {
      gsap.to(cloverRef.current, {
        x: 0,
        delay: 0.5,
        duration: 1,
        overwrite: true,
        ease: "power3.inOut",
      });
      if (XR8) {
        gsap.to(captureButtonRef.current, {
          y: 0,
          delay: 0.5,
          duration: 1,
          overwrite: true,
          ease: "power3.inOut",
        });
      }
      gsap.to(immersiveButtonRef.current, {
        x: 0,
        delay: 0.5,
        duration: 1,
        overwrite: true,
        ease: "power3.inOut",
      });

      gsap.to(soundButtonRef.current, {
        x: 0,
        delay: 0.5,
        duration: 1,
        overwrite: true,
        ease: "power3.inOut",
      });
    }
    return () => {
      gsap.killTweensOf([
        cloverRef.current,
        toggleRef.current,
        soundOnRef.current,
        toggleBGRef.current,
        soundOffRef.current,
        retakeSaveRef.current,
        captureButtonRef.current,
        immersiveButtonRef.current,
        soundButtonRef.current,
        photoContainerRef.current,
      ]);
    };
  }, [isScenePlaced, hudPhase, isTouchpointInstruction, XR8]);

  return (
    <Fragment>
      <SoundButton ref={soundButtonRef}>
        <button className="sound-state" onClick={handleSoundState}>
          <img
            draggable="false"
            ref={soundOnRef}
            className="sound-on"
            src="/ui/3-ar_launch/sound_on.svg"
            alt="sound_on"
          />
          <img
            draggable="false"
            ref={soundOffRef}
            className="sound-off"
            src="/ui/3-ar_launch/sound_off.svg"
            alt="sound_off"
          />
        </button>
      </SoundButton>
      <ImmersiveButton ref={immersiveButtonRef}>
        <button
          className="immersive-toggle"
          onPointerOut={scaleUpImmersiveButton}
          onPointerDown={handleImmersivePointerDown}
          onPointerUp={handleImmersivePointerUp}
        >
          <div className="toggle-bg" />
          <div ref={toggleBGRef} className="toggle-yellow" />
          <div ref={toggleRef} className="toggle-container">
            <a.div className="toggle" style={{ scale: immersiveBtnScale }} />
          </div>
        </button>
      </ImmersiveButton>

      <PhotoContainer ref={photoContainerRef}>
        {photo && <Photo src={photo} alt={"4h_hughsnet_ar_capture"} />}
      </PhotoContainer>

      <CaptureButton
        ref={captureButtonRef}
        onPointerOut={scaleUpCaptureButton}
        onPointerDown={handleCapturePointerDown}
        onPointerUp={handleCapturePointerUp}
      >
        <a.img
          draggable="false"
          style={{ scale: captureBtnScale }}
          src="/ui/3-ar_launch/capture.svg"
          alt="capture_button"
        />
      </CaptureButton>
      <CloverLogo ref={cloverRef}>
        <img
          draggable="false"
          src="/ui/3-ar_launch/clover.svg"
          alt="clover_logo"
        />
      </CloverLogo>
      <RetakeSave ref={retakeSaveRef}>
        <button
          className="retake-btn"
          onPointerOut={scaleUpRetakeButton}
          onPointerDown={handleRetakePointerDown}
          onPointerUp={handleRetakePointerUp}
        >
          <a.img
            draggable="false"
            style={{ scale: retakeBtnScale }}
            src="/ui/3-ar_launch/retake.svg"
            alt="retake_photo"
          />
        </button>
        <button
          className="save-btn"
          onPointerOut={scaleUpSaveButton}
          onPointerDown={handleSavePointerDown}
          onPointerUp={handleSavePointerUp}
        >
          <a.img
            draggable="false"
            style={{ scale: saveBtnScale }}
            src="/ui/3-ar_launch/save.svg"
            alt="save_photo"
          />
        </button>
      </RetakeSave>
      {InfoBoxData.map(
        ({ id, item, icon, color, text, audio, stateTrigger }) => {
          return (
            <InfoBox
              key={id}
              item={item}
              icon={icon}
              color={color}
              text={text}
              audio={audio}
              stateTrigger={stateTrigger}
            />
          );
        }
      )}
      {isTouchpointInstruction && <TouchPointInstruct />}
    </Fragment>
  );
};

export default memo(ARLaunch);

const CloverLogo = styled.div`
  ${MarginPaddingNone}
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  transform: translate(-100px, 0px);
  img {
    ${MarginPaddingNone}
    width: 100%;
    height: 100%;
  }
`;

const ImmersiveButton = styled.div`
  ${MarginPaddingNone}
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  transform: translate(130px, 0px);

  .immersive-toggle {
    ${MarginPaddingNone}
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: none;
    border: none;
    margin-bottom: 0.5rem;
    .toggle-bg {
      ${MarginPaddingNone}
      position: relative;
      background-image: url("/ui/3-ar_launch/Green_button.jpg");
      width: 5.3rem;
      height: 2.5rem;
      border-radius: 2rem;
      border: 2px solid white;
    }

    .toggle-yellow {
      ${MarginPaddingNone}
      opacity: 0;
      position: absolute;
      background-color: #fdc217;
      width: 5.3rem;
      height: 2.5rem;
      border-radius: 2rem;
      border: 1px solid white;
    }

    .toggle-container {
      position: absolute;
      .toggle {
        ${MarginPaddingNone}
        background: none;
        border: none;
        background-color: white;
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 2rem;
        margin-left: 0.4rem;
      }
    }
  }
`;

const SoundButton = styled.div`
  ${MarginPaddingNone}
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  transform: translate(130px, 0px);

  .sound-state {
    ${MarginPaddingNone}
    background: none;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 2rem;
    .sound-on {
      ${MarginPaddingNone}
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .sound-off {
      ${MarginPaddingNone}
      visibility: hidden;
      width: 100%;
      height: 100%;
    }
  }
`;

const CaptureButton = styled.button`
  ${MarginPaddingNone}
  bottom: 0;
  position: fixed;
  background: none;
  border: none;
  left: 50%;
  margin-bottom: 1rem;
  transform: translate(-50%, 200px);
  width: 4.2rem;
  img {
    ${MarginPaddingNone}
    width: 100%;
    height: 100%;
  }
`;

const RetakeSave = styled.div`
  ${MarginPaddingNone}
  bottom: 0;
  margin-bottom: 2.5rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  transform: translate(0px, 200px);
  .retake-btn {
    ${MarginPaddingNone}
    background: none;
    border: none;
    img {
      ${MarginPaddingNone}
      width: 100%;
      border-radius: 2rem;
      height: 100%;
    }
  }
  .save-btn {
    ${MarginPaddingNone}
    background: none;
    border: none;
    img {
      ${MarginPaddingNone}
      width: 100%;
      height: 100%;
      border-radius: 2rem;
    }
  }
`;

const PhotoContainer = styled(PageContainer)`
  opacity: 0;
  display: none;
`;

const Photo = styled.img`
  ${MarginPaddingNone};
  width: 100%;
`;
