// React
import { memo } from "react";

// Global State
import { useGlobalState } from "../state/useGlobalState";

// Styled Components
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

// Polished
import { normalize } from "polished";

// React-Div-100vh
import Div100vh from "react-div-100vh";

// Theme
import { Theme } from "./Theme";

// Attributes
import { MarginPaddingNone, UserSelectNone } from "./Attributes";

const GlobalStyle = createGlobalStyle`
    ${normalize()}

    * {
    ${UserSelectNone}
    ${MarginPaddingNone}
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

    html,
    body,
    #root {
        ${UserSelectNone}
        ${MarginPaddingNone}
    }

    html {
      background-color: #004438;
    }

    body{
        ${UserSelectNone}
        ${MarginPaddingNone}
        background-color: ${(props) =>
          props.bodyColor ? "#339966" : "#004438"};
        transition: ${(props) =>
          props.bodyColor ? "background-color 0.39s" : "background-color 0s"};;
        transition-delay: .07s;
        print-color-adjust: exact;
        color: white;
        overflow: hidden;
    }

    canvas {
      ${UserSelectNone}
      ${MarginPaddingNone}
      touch-action: none;
    }

    @font-face {
      font-family: "GilroyRegular";
      src: local('GilroyRegular'),
      url(/fonts/Gilroy-Regular.otf) format('opentype');
    }

    @font-face {
      font-family: "GilroySemiBold";
      src: local('GilroySemiBold'),
      url(/fonts/Gilroy-SemiBold.otf) format('opentype');
    }
`;

const Container = styled(Div100vh)`
  width: 100vw;
`;

const StyleProvider = ({ children }) => {
  // Global State
  const bodyColor = useGlobalState((state) => state.bodyColor);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle bodyColor={bodyColor} />
      <Container>{children}</Container>
    </ThemeProvider>
  );
};

export default memo(StyleProvider);
