// React
import { useRef, useMemo, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { RawShaderMaterial, CylinderGeometry } from "three";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Instances, Instance } from "@react-three/drei";

// GSAP
import gsap from "gsap";

// Data
import { transmissionData } from "./TransmissionsData";

// WebGL
import { whiteNoiseAlgo } from "../../4-WebGL/noiseAlgorithms";

const Transmissions = () => {
  // Refs
  const transmissionRef = useRef();

  // Global State
  const view = useGlobalState((state) => state.view);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);

  // Shader Attributes
  const uniforms = {
    uTime: { value: 0 },
    uAlpha: { value: 0 },
  };

  const vertexShader = `
    precision highp float;
    uniform mat4 projectionMatrix;
    uniform mat4 modelViewMatrix;
    uniform float uTime;
    
    attribute vec3 position;
    attribute mat4 instanceMatrix;

    varying vec3 vPos;

    void main() {
        float delta = (sin(uTime * 6.5) + 1.0) / 4.0;
        vec3 normalizePosition = normalize(position) * 2.1;
        vec3 pulsePosition = mix(position, normalizePosition, delta);
        gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(vec3(pulsePosition.x, position.y, pulsePosition.z), 1.0);
        vPos = position;
    }
`;

  const fragmentShader = `
    precision highp float;
    uniform float uTime;
    uniform float uAlpha;
    varying vec3 vPos;

    ${whiteNoiseAlgo}
    
    void main() {
      vec2 noiseCoord = sin(vPos.xy * uTime) * 50.0;
      float grain = generateWhiteNoise(vec2(fract(sin(noiseCoord))));
      vec3 grainColor = vec3(grain);
      vec3 baseColor = vec3(0.5, 0.9, 1.0);
      vec3 noiseColor = vec3(grainColor.r * 0.5, grainColor.g * 0.9, grainColor.b);
      vec3 finalColor = mix(baseColor, noiseColor, 0.5) * 1.5;
      float modulateAlphaF = fract(vPos.y * 3.0 - uTime) - 0.05;
      float modulateAlphaB = 0.5 - fract(vPos.y * 3.0 - uTime) / 2.0 - 0.05;
      float fadeAlphaStart = smoothstep(0.0, 0.8, 6.5 + vPos.y * 3.5);
      float fadeAlphaEnd = smoothstep(0.0, 0.8, 6.5 - vPos.y * 3.5);
      float finalAlpha = fadeAlphaStart *= fadeAlphaEnd *= modulateAlphaF;
      gl_FragColor = vec4(finalColor, finalAlpha * uAlpha);
    }
`;

  // Shader Material
  const { transmissionMat, transmissionGeo } = useMemo(() => {
    const transmissionGeo = new CylinderGeometry(
      0.05,
      0.05,
      10,
      50,
      1,
      true,
      0.0,
      Math.PI * 2
    );
    const transmissionMat = new RawShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
    });

    return { transmissionMat, transmissionGeo };
  }, []);

  // onFrame
  useFrame(({ clock }) => {
    transmissionRef.current.material.uniforms.uTime.value =
      clock.getElapsedTime();
  });

  // View Change
  useEffect(() => {
    if (isScenePlaced) {
      if (
        /Global/gi.test(view) ||
        /Gateway/gi.test(view) ||
        /House/gi.test(view) ||
        /Reflectors/gi.test(view)
      ) {
        gsap.to(transmissionMat.uniforms.uAlpha, {
          value: 1,
          duration: 3,
          overwrite: true,
          ease: "power3.inOut",
        });
      } else {
        gsap.to(transmissionMat.uniforms.uAlpha, {
          value: 0,
          duration: 3,
          overwrite: true,
          ease: "power3.inOut",
        });
      }
    }
  }, [isScenePlaced, view]);

  return (
    <group renderOrder={3}>
      <Instances
        ref={transmissionRef}
        geometry={transmissionGeo}
        material={transmissionMat}
      >
        {transmissionData.map(({ id, scale, position, rotation }) => {
          return (
            <Instance
              key={id}
              scale={scale}
              position={position}
              rotation={rotation}
            />
          );
        })}
      </Instances>
    </group>
  );
};

export default memo(Transmissions);
