// React
import { useRef, useState, useEffect, memo } from "react";

// State
import { useGlobalState } from "../../../state/useGlobalState";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a, animated, config } from "@react-spring/web";

// Use Gesture
import { useGesture } from "@use-gesture/react";

// Styled Components
import styled from "styled-components";

// Styles
import { MarginPaddingNone } from "../../../styles/Attributes";

const InfoBox = ({ item, icon, color, text, audio, stateTrigger }) => {
  // Refs
  const infoBoxContainerRef = useRef();
  const dotOneRef = useRef();
  const dotTwoRef = useRef();

  // Global State
  const view = useGlobalState((state) => state.view);
  const setStopAudio = useGlobalState((state) => state.setStopAudio);
  const setCurrentlyPlaying = useGlobalState(
    (state) => state.setCurrentlyPlaying
  );
  const setView = useGlobalState((state) => state.setView);
  const hideInfoBox = useGlobalState((state) => state.hideInfoBox);

  // Local State
  const [activeDot, setActiveDot] = useState(1);

  // Animations
  const [{ btnScale }, setBtnSpring] = useSpring(() => ({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    btnScale: 1,
  }));

  const handleCloseInfo = () => {
    setStopAudio(audio);
    setCurrentlyPlaying(null);
    setView("Global");
    gsap.to(infoBoxContainerRef.current, {
      x: 400,
      delay: 0.2,
      ease: "power3.inOut",
      overwrite: true,
    });
  };

  const scaleUpButton = () => {
    setBtnSpring.start({
      btnScale: 1,
    });
  };

  const handlePointerDown = (e) => {
    e.stopPropagation();
    setBtnSpring.start({
      btnScale: 0.85,
    });
  };

  const handlePointerUp = () => {
    scaleUpButton();
    handleCloseInfo();
  };

  useEffect(() => {
    if (view !== stateTrigger || hideInfoBox) {
      gsap.to(infoBoxContainerRef.current, {
        x: 400,
        duration: 1.3,
        ease: "power3.inOut",
        overwrite: true,
      });
    } else {
      gsap.to(infoBoxContainerRef.current, {
        x: 0,
        duration: 1.3,
        delay: 0.6,
        ease: "power3.inOut",
        overwrite: true,
      });
    }

    return () => {
      gsap.killTweensOf([
        infoBoxContainerRef.current,
        dotOneRef.current,
        dotTwoRef.current,
      ]);
    };
  }, [view, hideInfoBox]);

  // Animation State
  const [{ x }, setSlideSpring] = useSpring(() => ({
    x: 0,
    config: { ...config.stiff, precision: 0.001 },
  }));

  const { dotOneColor, dotOneOp } = useSpring({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    dotOneColor: activeDot === 1 ? color : "white",
    dotOneOp: activeDot === 1 ? 1 : 0.5,
  });

  const { dotTwoColor, dotTwoOp } = useSpring({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    dotTwoColor: activeDot === 2 ? color : "white",
    dotTwoOp: activeDot === 2 ? 1 : 0.5,
  });

  // Gesture State
  const bindSliderTransforms = useGesture(
    {
      onDrag: ({ dragging, movement: [mx, my] }) => {
        if (dragging) {
          if (activeDot === 1) {
            setSlideSpring.start({ x: mx });
          } else if (activeDot === 2) {
            setSlideSpring.start({ x: mx - 320 });
          }
        } else {
          if (mx < 0) {
            setActiveDot(2);
            setSlideSpring.start({ x: -320 });
          } else if (mx > 0) {
            setActiveDot(1);
            setSlideSpring.start({ x: 0 });
          }
        }
      },
    },
    {
      eventOptions: {
        passive: false,
      },
      drag: {
        filterTaps: true,
        bounds: { left: -320, right: 0, top: 0, bottom: 0 },
      },
    }
  );

  return item === "Solar Arrays" || item === "Bus" ? (
    <InfoBoxContainer ref={infoBoxContainerRef}>
      <div className="blur-bg" />
      <div className="info-container">
        <div className="top-row">
          <div className="header">
            <h2 className="item-name">{item}</h2>
            <img
              draggable="false"
              className="icon"
              src={`/ui/3-ar_launch/${icon}.svg`}
              alt={icon}
            />
          </div>
          <a.button
            className="close-btn"
            style={{ scale: btnScale }}
            onPointerOut={scaleUpButton}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
          >
            <lottie-player
              src="/ui/3-ar_launch/close.json"
              mode="normal"
              loop="true"
              autoplay
            />
          </a.button>
        </div>

        <div
          className="img-info-block"
          style={{ borderColor: color, touchAction: "none" }}
          {...bindSliderTransforms()}
        >
          <animated.p className="info-paragraph" style={{ x }}>
            {text}
          </animated.p>
          <animated.div className="size-img-container" style={{ x }}>
            <img
              draggable="false"
              className="info-img"
              src={`/ui/3-ar_launch/${icon}.png`}
              alt={icon}
            />
          </animated.div>
        </div>
      </div>
      <div className="dot-indicators">
        <animated.div
          ref={dotOneRef}
          style={{ backgroundColor: dotOneColor, opacity: dotOneOp }}
          className="dot-one"
        />
        <animated.div
          style={{ backgroundColor: dotTwoColor, opacity: dotTwoOp }}
          ref={dotTwoRef}
          className="dot-two"
        />
      </div>
    </InfoBoxContainer>
  ) : (
    <InfoBoxContainer ref={infoBoxContainerRef}>
      <div className="blur-bg" />
      <div className="info-container">
        <div className="top-row">
          <div className="header">
            <h2 className="item-name">{item}</h2>
            <img
              draggable="false"
              className="icon"
              src={`/ui/3-ar_launch/${icon}.svg`}
              alt={icon}
            />
          </div>
          <a.button
            className="close-btn"
            style={{ scale: btnScale }}
            onPointerOut={scaleUpButton}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
          >
            <lottie-player
              src="/ui/3-ar_launch/close.json"
              mode="normal"
              loop="true"
              autoplay
            />
          </a.button>
        </div>

        <div className="info-block">
          <p className="info-paragraph" style={{ borderColor: color }}>
            {text}
          </p>
        </div>
      </div>
    </InfoBoxContainer>
  );
};

export default memo(InfoBox);

const InfoBoxContainer = styled.div`
  ${MarginPaddingNone}
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 6.5rem;
  transform: translate(400px, 0px);
  .blur-bg {
    ${MarginPaddingNone}
    position: absolute;
    background-color: black;
    border-radius: 1rem 0rem 0rem 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(0.2rem);
    width: 100%;
    height: 100%;
  }
  .info-container {
    ${MarginPaddingNone}
    position: relative;
    padding: 1rem;
    padding-left: 1.5rem;

    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header {
        ${MarginPaddingNone}
        display: flex;
        align-items: center;
        jusify-content: center;
        margin-bottom: 0.5rem;
        .item-name {
          ${MarginPaddingNone}
          font-family: "GilroyRegular";
          letter-spacing: 0rem;
          font-size: 1.4rem;
          margin-right: 1.3rem;
        }
        .icon {
          ${MarginPaddingNone}
          padding-bottom: .6rem;
        }
      }
      .close-btn {
        ${MarginPaddingNone}
        background: none;
        border: none;
        padding-bottom: 0.7rem;
        width: 1.4rem;
      }
    }

    .info-block {
      ${MarginPaddingNone}
      .info-paragraph {
        ${MarginPaddingNone}
        font-family: "GilroyRegular";
        font-size: 0.85rem;
        width: 20rem;
        padding-left: 1.5rem;
        margin-left: 0.1rem;
        border-left: 4px solid;
      }
    }

    .img-info-block::-webkit-scrollbar {
      display: none;
    }

    .img-info-block {
      back-ground-color: red;
      ${MarginPaddingNone}
      border-left: 4px solid;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      width: 20rem;
      height: 6.6rem;

      .info-paragraph {
        back-ground-color: red;
        ${MarginPaddingNone}
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "GilroyRegular";
        min-width: 100%;
        min-height: 100%;
        font-size: 0.85rem;
        padding-left: 0.8rem;
        padding-top: 0.15rem;
      }

      .size-img-container {
        back-ground-color: red;
        ${MarginPaddingNone}
        min-width: 100%;
        min-height: 100%;
        .info-img {
          ${MarginPaddingNone}
          back-ground-color: red;
          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -o-user-select: none;
          user-select: none;
          user-drag: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .dot-indicators {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0.4rem;
    transform: translate(0, -13px);
    .dot-one {
      height: 100%;
      width: 0.4rem;
      margin-right: 0.3rem;
      border-radius: 2rem;
      background-color: white;
      opacity: 0.4;
    }
    .dot-two {
      height: 100%;
      width: 0.4rem;
      margin-left: 0.3rem;
      border-radius: 2rem;
      background-color: white;
      opacity: 0.4;
    }
  }
`;
