const ionThrusterData = [
  {
    id: 0,
    scale: [0.14, 0.1, 0.14],
    position: [0.451, 0.026, 0.167],
    rotation: [0, 5.97, 1.65],
  },
  {
    id: 1,
    scale: [0.14, 0.1, 0.14],
    position: [0.462, 0.02, 0.136],
    rotation: [0, 5.97, 1.65],
  },
  {
    id: 2,
    scale: [0.14, 0.1, 0.14],
    position: [-0.1945, 0.0807, 0.437],
    rotation: [0.0, 4.25, 1.77],
  },
  {
    id: 3,
    scale: [0.14, 0.1, 0.14],
    position: [-0.224, 0.078, 0.422],
    rotation: [0.0, 4.25, 1.77],
  },
];

export { ionThrusterData };
