// React
import { useMemo, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { RawShaderMaterial, DoubleSide, CylinderGeometry } from "three";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Instances, Instance } from "@react-three/drei";

// gsap
import gsap from "gsap";

// Data
import { ionThrusterData } from "./IonThrusterData";

// WebGL
import {
  map,
  permute,
  taylorInvSqrt,
  fade,
  pNormalize,
} from "../../4-WebGL/utilityFunctions";

import { whiteNoiseAlgo, perlinNoiseAlgo } from "../../4-WebGL/noiseAlgorithms";

const IonThruster = () => {
  // Global State
  const view = useGlobalState((state) => state.view);

  // Shader Attributes
  const uniforms = {
    uTime: { value: 0 },
    uAlpha: { value: 0 },
  };

  const vertexShader = `
    precision highp float;
    uniform mat4 projectionMatrix;
    uniform mat4 modelViewMatrix;
    uniform float uTime;
    
    attribute vec3 position;
    attribute mat4 instanceMatrix;

    varying vec3 vPos;

    ${map}

    ${permute}

    ${taylorInvSqrt}

    ${fade}

    ${pNormalize}

    ${perlinNoiseAlgo}

    void main() {
        float delta = (sin(50.0) + 1.0) / 250.0;
        vec3 normalizePosition = normalize(vec3(position.x, position.y - 0.15, position.z)) * 15.0;
        vec3 pulsePosition = mix(position, normalizePosition, delta);
        float perlinNoise = generatePerlinNoise(vec3(position.x * .1, position.y + uTime * 3.0, position.z * .1) / .5);
        vec3 perlinPosition = vec3(mix(pulsePosition.x, pulsePosition.x * perlinNoise, 0.3), pulsePosition.y, mix(pulsePosition.z, pulsePosition.z * perlinNoise, 0.2));
        gl_Position = projectionMatrix * modelViewMatrix * instanceMatrix * vec4(perlinPosition, 1.0);
        vPos = position;
    }
`;

  const fragmentShader = `
    precision highp float;
    uniform float uTime;
    uniform float uAlpha;
    varying vec3 vPos;

    ${map}

    ${permute}

    ${taylorInvSqrt}

    ${fade}

    ${pNormalize}

    ${perlinNoiseAlgo}

    ${whiteNoiseAlgo}

    void main() {
        vec2 noiseCoord = vec2(sin(vPos.x + uTime), vPos.y + uTime) * 50.0;
        float whiteNoise = generateWhiteNoise(vec2(fract(sin(noiseCoord))));
        vec3 whiteColor = vec3(whiteNoise * 0.5, whiteNoise * 0.9, whiteNoise);
        float perlinNoise = generatePerlinNoise(vec3(vPos.x, vPos.y + uTime * 3.0, vPos.z) / 0.5);
        vec3 perlinColor2 = vec3(perlinNoise * 0.5, perlinNoise * 0.9, perlinNoise);
        vec3 baseColor = vec3(0.5, 0.7, 1.0) * 1.3;
        float fadeAlphaStart = smoothstep(-1.0, 5.0, 2.0 + vPos.y * 8.0);
        float fadeAlphaEnd = smoothstep(0.0, 1.0, 1.5 - vPos.y * 3.0);
        float finalAlpha = fadeAlphaStart *= fadeAlphaEnd *= perlinNoise *= whiteNoise;
        gl_FragColor = vec4(baseColor, finalAlpha * uAlpha * 0.7);
    }
`;

  const { ionThrusterMat, ionThrusterGeo } = useMemo(() => {
    const ionThrusterGeo = new CylinderGeometry(
      0.1,
      0.01,
      1,
      100,
      100,
      true,
      0.0,
      Math.PI * 2
    );
    const ionThrusterMat = new RawShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
      side: DoubleSide,
    });
    return { ionThrusterMat, ionThrusterGeo };
  }, []);

  // onFrame
  useFrame(({ clock }) => {
    ionThrusterMat.uniforms.uTime.value = clock.getElapsedTime();
  });

  useEffect(() => {
    if (/Thrusters/gi.test(view)) {
      gsap.to(ionThrusterMat.uniforms.uAlpha, {
        value: 6,
        duration: 2.0,
        ease: "back.out(.7)",
        delay: 0.5,
        overwrite: true,
      });
    } else {
      gsap.to(ionThrusterMat.uniforms.uAlpha, {
        value: 0,
        duration: 0.5,
        ease: "back.out(.7)",
        delay: 0.2,
        overwrite: true,
      });
    }
  }, [view]);

  return (
    <group renderOrder={3}>
      <Instances geometry={ionThrusterGeo} material={ionThrusterMat}>
        {ionThrusterData.map(({ id, scale, position, rotation }) => {
          return (
            <Instance
              key={id}
              scale={scale}
              position={position}
              rotation={rotation}
            />
          );
        })}
      </Instances>
    </group>
  );
};

export default memo(IonThruster);
