// React
import { useRef, useEffect, useState, memo } from "react";

// State
import { useGlobalState } from "../../../state/useGlobalState";

// Styled Components
import styled from "styled-components";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a } from "@react-spring/web";

// Components
import Instruction from "./Instruction";

// Styles
import { PageContainer } from "../../../styles/Containers";
import { MarginPaddingNone } from "../../../styles/Attributes";

const Instruct = () => {
  // Refs
  const moveRef = useRef();
  const rotateRef = useRef();
  const scaleUpRef = useRef();
  const scaleDownRef = useRef();
  const gotItRef = useRef();
  const proceedRef = useRef();
  const initContainerRef = useRef();
  const scanFloorRef = useRef();
  const tapRef = useRef();
  const immersiveInstructionRef = useRef();
  const toggleIntervalRef = useRef();
  const toggleCounterRef = useRef(0);
  const dummyRef = useRef(1);

  // Local State
  const [allowImmInstruct, setAllowImmInstruct] = useState(true);

  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const setHudPhase = useGlobalState((state) => state.setHudPhase);
  const setIsImmersive = useGlobalState((state) => state.setIsImmersive);
  const isImmersive = useGlobalState((state) => state.isImmersive);

  // Data
  const instructionData = [
    {
      id: 0,
      ref: moveRef,
      name: "move",
      copy: "Use your finger to move the object",
    },
    {
      id: 1,
      ref: rotateRef,
      name: "rotate",
      copy: "Rotate",
    },
    {
      id: 2,
      ref: scaleUpRef,
      name: "scale_up",
      copy: "Scale Up",
    },
    {
      id: 3,
      ref: scaleDownRef,
      name: "scale_down",
      copy: "Scale Down",
    },
  ];

  // Animations
  const springConfig = {
    mass: 1,
    tension: 300,
    friction: 12,
    precision: 0.001,
  };

  const [{ instructBtnScale }, setInstructBtnSpring] = useSpring(() => ({
    config: springConfig,
    instructBtnScale: 1,
  }));

  const [{ immersedBtnScale }, setImmersedBtnSpring] = useSpring(() => ({
    config: springConfig,
    immersedBtnScale: 1,
  }));

  const { yellowOpacity, togglePos, onOpacity, offOpacity } = useSpring({
    config: {
      mass: 1,
      tension: 80,
      friction: 12,
      precision: 0.001,
      clamp: true,
    },
    yellowOpacity: isImmersive ? 1 : 0,
    togglePos: isImmersive ? 0 : 45,
    onOpacity: isImmersive ? 1 : 0,
    offOpacity: isImmersive ? 0 : 1,
  });

  const tl = gsap.timeline();

  const startTargetInstruct = () => {
    tl.fromTo(
      scanFloorRef.current,
      { opacity: 0, y: -10 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power3.inOut",
      }
    );
    tl.to(scanFloorRef.current, {
      y: -10,
      opacity: 0,
      delay: 2,
      duration: 0.8,
      ease: "power3.inOut",
    });
    tl.fromTo(
      tapRef.current,
      { opacity: 0, y: -10 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power3.inOut",
      }
    );
    tl.to(tapRef.current, {
      y: -10,
      opacity: 0,
      delay: 2,
      duration: 0.8,
      ease: "power3.inOut",
      onComplete: () => {
        setHudPhase("ARLaunch");
      },
    });
  };

  const handleScanPhase = () => {
    setAllowImmInstruct(false);
    setIsImmersive(false);
    clearInterval(toggleIntervalRef.current);
    gsap.to(dummyRef.current, {
      duration: 0.0001,
      overwrite: true,
    });

    gsap.to(immersiveInstructionRef.current, {
      y: -10,
      opacity: 0,
      overwrite: true,
      ease: "power3.inOut",
      onComplete: () => {
        startTargetInstruct();
      },
    });
  };

  const toggleFunc = () => {
    if (toggleCounterRef.current <= 2) {
      if (!isImmersive) {
        setIsImmersive(true);
        gsap.to(dummyRef.current, {
          delay: 1.5,
          duration: 0.0001,
          overwrite: true,
          onComplete: () => {
            setIsImmersive(false);
          },
        });
      }
    } else {
      setIsImmersive(false);
      clearInterval(toggleIntervalRef.current);
    }
    toggleCounterRef.current += 1;
  };

  const runInterval = () => {
    toggleIntervalRef.current = setInterval(() => {
      if (allowImmInstruct) {
        toggleFunc();
      }
    }, 3000);
  };

  const startImmersedLesson = () => {
    gsap.fromTo(
      immersiveInstructionRef.current,
      { opacity: 0, y: -10 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.inOut",
        onComplete: () => {
          runInterval();
          toggleFunc();
        },
      }
    );
  };

  // NextPhase
  const handleImmersedPhase = () => {
    gsap.to(
      [
        gotItRef.current,
        scaleDownRef.current,
        scaleUpRef.current,
        rotateRef.current,
        moveRef.current,
      ],
      {
        y: -10,
        opacity: 0,
        ease: "power3.inOut",
        overwrite: true,
        stagger: {
          each: 0.1,
        },
      }
    );

    gsap.to(initContainerRef.current, {
      opacity: 0,
      ease: "power3.inOut",
      delay: 0.8,
      onComplete: () => {
        startImmersedLesson();
        gsap.to(initContainerRef.current, {
          display: "none",
          duration: 0.00001,
        });
      },
    });
  };

  const handleMount = () => {
    gsap.fromTo(
      [
        moveRef.current,
        rotateRef.current,
        scaleUpRef.current,
        scaleDownRef.current,
        gotItRef.current,
      ],
      { opacity: 0, y: -10 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.inOut",
        overwrite: true,
        stagger: {
          each: 0.1,
        },
      }
    );
  };

  const scaleUpInstructButton = () => {
    setInstructBtnSpring.start({
      instructBtnScale: 1,
    });
  };

  const handleInstructPointerDown = (e) => {
    e.stopPropagation();
    setInstructBtnSpring.start({
      instructBtnScale: 0.85,
    });
  };

  const handleInstructPointerUp = () => {
    scaleUpInstructButton();
    handleImmersedPhase();
  };

  const scaleUpImmersedButton = () => {
    setImmersedBtnSpring.start({
      immersedBtnScale: 1,
    });
  };

  const handleImmersedPointerDown = (e) => {
    e.stopPropagation();
    setImmersedBtnSpring.start({
      immersedBtnScale: 0.85,
    });
  };

  const handleImmersedPointerUp = () => {
    scaleUpImmersedButton();
    handleScanPhase();
  };

  useEffect(() => {
    if (/Instruct/gi.test(hudPhase)) {
      handleMount();
    }

    return () => {
      tl.kill();
      gsap.killTweensOf([
        moveRef.current,
        rotateRef.current,
        scaleUpRef.current,
        scaleDownRef.current,
        gotItRef.current,
        proceedRef.current,
        initContainerRef.current,
        scanFloorRef.current,
        tapRef.current,
        immersiveInstructionRef.current,
      ]);
      clearInterval(toggleIntervalRef.current);
    };
  }, [hudPhase]);

  return (
    <InstructContainer>
      <div ref={scanFloorRef} className="astro-scan">
        <lottie-player
          src="/ui/2-instruct/sat_scan.json"
          mode="normal"
          loop="true"
          autoplay
        />
      </div>

      <div ref={tapRef} className="globe">
        <lottie-player
          src="/ui/2-instruct/globe.json"
          mode="normal"
          loop="true"
          autoplay
        />
        <h3 className="loading">loading...</h3>
      </div>

      <ImmersiveInstruction ref={immersiveInstructionRef}>
        <h1 className="immersed-header">Immersed mode</h1>
        <div className="immersive-toggle">
          <div className="toggle-bg" />
          <a.div style={{ opacity: yellowOpacity }} className="toggle-yellow" />
          <a.div style={{ x: togglePos }} className="toggle-container">
            <div className="toggle" />
          </a.div>
        </div>
        <div className="on-off-container">
          <a.h1 className="on" style={{ opacity: onOpacity }}>
            ON
          </a.h1>
          <a.h1 className="off" style={{ opacity: offOpacity }}>
            OFF
          </a.h1>
        </div>

        <a.button
          ref={proceedRef}
          style={{ scale: immersedBtnScale }}
          className="proceed-p"
          onPointerOut={scaleUpImmersedButton}
          onPointerDown={handleImmersedPointerDown}
          onPointerUp={handleImmersedPointerUp}
        >
          Proceed
        </a.button>
      </ImmersiveInstruction>

      <div ref={initContainerRef} className="init-container">
        <div className="blur-overlay" />
        {instructionData.map(({ id, ref, name, copy }) => {
          return <Instruction key={id} ref={ref} name={name} copy={copy} />;
        })}

        <a.button
          ref={gotItRef}
          style={{ scale: instructBtnScale }}
          className="proceed"
          onPointerOut={scaleUpInstructButton}
          onPointerDown={handleInstructPointerDown}
          onPointerUp={handleInstructPointerUp}
        >
          Got it
        </a.button>
      </div>
    </InstructContainer>
  );
};

export default memo(Instruct);

const InstructContainer = styled(PageContainer)`
  .init-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .blur-overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      margin: 0;
      padding: 0;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(8px);
    }

    .proceed {
      ${MarginPaddingNone};
      opacity: 0;
      border: none;
      background: none;
      width: 12rem;
      height: 3rem;
      background-color: #339966;
      border-radius: 1.5rem;
      font-family: "GilroySemiBold";
      letter-spacing: 0.05rem;
      text-align: center;
      color: white;
      font-size: 1.3rem;
    }
  }
  .astro-scan {
    position: relative;
    opacity: 0;
    width: 15rem;
  }
  .globe {
    position: absolute;
    opacity: 0;
    width: 15rem;
    .loading {
      ${MarginPaddingNone};
      font-family: "GilroyRegular";
      text-align: center;
    }
  }
`;

const ImmersiveInstruction = styled.div`
  ${MarginPaddingNone}
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .immersed-header {
    ${MarginPaddingNone}
    font-family: "GilroyRegular";
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .immersive-toggle {
    ${MarginPaddingNone}
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: none;
    border: none;
    margin-bottom: 2rem;
    .toggle-bg {
      ${MarginPaddingNone}
      position: relative;
      background-image: url("/ui/3-ar_launch/Green_button.jpg");
      width: 5.3rem;
      height: 2.5rem;
      border-radius: 2rem;
      border: 2px solid white;
    }

    .toggle-yellow {
      ${MarginPaddingNone}
      opacity: 0;
      position: absolute;
      background-color: #fdc217;
      width: 5.3rem;
      height: 2.5rem;
      border-radius: 2rem;
      border: 1px solid white;
    }

    .toggle-container {
      position: absolute;
      .toggle {
        ${MarginPaddingNone}
        background: none;
        border: none;
        background-color: white;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 2rem;
        margin-left: 0.4rem;
      }
    }
  }

  .on-off-container {
    ${MarginPaddingNone}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .on {
      ${MarginPaddingNone}
      position: relative;
      font-family: "GilroySemiBold";
      text-align: center;
      font-size: 1.8rem;
    }
    .off {
      ${MarginPaddingNone}
      position: absolute;
      font-family: "GilroySemiBold";
      text-align: center;
      font-size: 1.8rem;
    }
  }

  .proceed-p {
    ${MarginPaddingNone};
    position: fixed;
    bottom: 0;
    margin-bottom: 3rem;
    border: none;
    background: none;
    width: 12rem;
    height: 3rem;
    background-color: #339966;
    border-radius: 1.5rem;
    font-family: "GilroySemiBold";
    letter-spacing: 0.05rem;
    text-align: center;
    color: white;
    font-size: 1.3rem;
  }
`;
