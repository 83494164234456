// React
import { memo, Fragment } from "react";

// Components
import ImmersiveOrb from "./ImmersiveOrb";
import ImmersiveParticleSystem from "./ImersiveParticleSystem";

const Immersive = ({ immersiveAtlas }) => {
  return (
    <Fragment>
      <ImmersiveParticleSystem />
      <ImmersiveOrb immersiveAtlas={immersiveAtlas} />
    </Fragment>
  );
};

export default memo(Immersive);
