const InfoBoxData = [
  {
    id: 0,
    item: "Satellite",
    audio: "Satellite",
    icon: "satellite",
    stateTrigger: "Satellite",
    color: "#339966",
    text: "Like a computer in space, a satellite is a machine that orbits the Earth. Some have cameras built-in for observing the planet. Others are used for broadcasting content, like satellite radio and TV. And others, like this one, are used for two-way communications.",
  },
  {
    id: 1,
    item: "Gateway",
    audio: "Gateway",
    icon: "gateway",
    color: "#339966",
    stateTrigger: "Gateway",
    text: "A gateway (sometimes called a “teleport” or “hub”) sends and receives data between the satellite and the internet. It acts like a virtual traffic interchange, with on- and off-ramps to the internet.",
  },
  {
    id: 2,
    item: "Home",
    audio: "House",
    icon: "house",
    color: "#339966",
    stateTrigger: "House",
    text: "At homes across the U.S. (and around the world), people depend on satellites for their internet service. They sync their devices to a modem inside the house that connects to a small, dish-like antenna on the outside of the house to send and receive signals to and from the satellite in space.",
  },
  {
    id: 3,
    item: "Bus",
    audio: "Bus",
    icon: "bus",
    color: "#339966",
    stateTrigger: "Bus",
    text: "This refers to the main structure or “body” of the satellite that contains the power and propulsion systems, the communications “payload” and all the engineering intelligence that delivers connectivity for thousands and thousands of customers!",
  },
  {
    id: 4,
    item: "Thrusters",
    audio: "Thrusters",
    icon: "thrusters",
    color: "#B4DCE4",
    stateTrigger: "Thrusters",
    text: "These tiny rockets attached to the bus can be fired by operators on Earth to adjust the satellite’s position in space and keep it within its designated orbital slot.",
  },
  {
    id: 5,
    item: "Reflectors",
    audio: "Reflectors",
    icon: "reflectors",
    color: "#F54359",
    stateTrigger: "Reflectors",
    text: "These antenna form hundreds of small “spot beams” that carry data signals to and from customers’ homes. They also send and receive data from gateways on Earth that connect with the internet so customers can enjoy all the benefits of connectivity.",
  },
  {
    id: 6,
    item: "Solar Arrays",
    audio: "SolarArray",
    icon: "solar_array",
    color: "#EFB398",
    stateTrigger: "SolarArray",
    text: "These “wings” are actually high-powered solar panels that collect the sun’s energy to power the satellite for its entire lifetime (about 15 years!). They are 50 feet long by 20 feet wide, which is approximately two semi trucks end to end.",
  },
  {
    id: 7,
    item: "Star Tracker",
    audio: "StarTracker",
    icon: "star_tracker",
    color: "#FDC217",
    stateTrigger: "StarTracker",
    text: "This navigational device helps keep the satellite in its orbit relative to the position of certain stars in the sky.",
  },
];

export { InfoBoxData };
