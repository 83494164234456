// React
import { memo, useMemo, useEffect, useRef } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { BackSide, RawShaderMaterial } from "three";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Sphere } from "@react-three/drei";

// Gsap
import gsap from "gsap";

// WebGL
import {
  map,
  permute,
  taylorInvSqrt,
  fade,
  pNormalize,
} from "../../4-WebGL/utilityFunctions";

import { vUvPosVertex } from "../../4-WebGL/vertexProjections";
import { perlinNoiseAlgo } from "../../4-WebGL/noiseAlgorithms";

const ImmersiveOrb = ({ immersiveAtlas }) => {
  // Refs
  const immersiveSphereRef = useRef();

  // Global State
  const isImmersive = useGlobalState((state) => state.isImmersive);

  const uniforms = {
    uTime: { value: 0 },
    uAlpha: { value: 0 },
    uTexture: { value: immersiveAtlas },
  };

  const fragmentShader = `
    precision highp float;  

    varying vec3 vPos;
    varying vec2 vUv;

    uniform float uTime;
    uniform float uAlpha;
    uniform sampler2D uTexture;

    ${map}

    ${permute}

    ${taylorInvSqrt}

    ${fade}

    ${pNormalize}

    ${perlinNoiseAlgo}

    mat2 rotate(float angle) {
        float angleSin = sin(angle);
        float angleCos = cos(angle);
        return mat2(angleCos, -angleSin, angleSin, angleCos);
    }

    float createStar(vec2 uv, float flareAtten) {
        float dist = length(uv);
        float m = 0.03 / dist; 
        float flare = max(0.0, 1.0 - abs(uv.x * uv.y * 200.0));
        m += flare * flareAtten;
        uv *= rotate(3.1415 / 4.0);
        flare = max(0.0, 1.0 - abs(uv.x * uv.y * 200.0));
        m += flare * 0.3 * flareAtten;
        m *= smoothstep(1.0, 0.01, dist);
        return m;
    }

    float hash21(vec2 p) {
        p = fract(p * vec2(123.34, 456.21));
        p += dot(p, p + 45.32);
        return fract(p.x * p.y);
    }

    vec3 stars(vec2 uv, float sFactor) {
        vec3 color = vec3(0.0);
        vec2 gv = fract(uv * sFactor) - 0.5;
        vec2 id = floor(uv * sFactor);
        for(int y = -1; y <= 1; y++) {
            for(int x = -1; x <= 1; x++) { 
                vec2 offset = vec2(x, y);
                float n = hash21(id + offset);
                float atten = fract(n * 636.32);
                float star = createStar(gv - offset - vec2(n, fract(n * 34.0)) + 0.5, atten * 0.5);
                vec3 colorVariant = sin(vec3(.6, .6, 1.0) * fract(n * 2345.2)) + 0.5;
                colorVariant = colorVariant * vec3(0.85, 0.95, 1.0) * 1.5;
                float size = fract(n * 345098.32);
                star *= sin(uTime * 2.0 + n * 6.2831) * 0.5 + 0.5;
                color += star * size * colorVariant;
            }
        }
        return color;
    }

    void main() {
        float sFactor = 120.0;
        vec3 universe = vec3(0.0);
        universe += stars(vUv , sFactor) * 1.5;
        vec2 sizedUV = vUv;
        vec3 colorMap = texture2D(uTexture,  vec2(sizedUV.x, sizedUV.y / 0.8)).rgb;
        float colorMapAlpha = texture2D(uTexture,  vec2(vUv.x, vUv.y)).r;
        float perlinNoise = generatePerlinNoise(vec3(vPos.x + uTime * 0.21, vPos.y + uTime * 0.21, vPos.z + uTime * 0.21) / 0.4);
        vec3 perlinMap = colorMap * perlinNoise * 1.3;
        vec3 galaxy = mix(universe, perlinMap, 1.0 - universe * 0.3);
        vec3 mixed = mix(galaxy, colorMap, 0.4);
        gl_FragColor = vec4(mixed, 1.0 * uAlpha);
    }    
`;

  const { immersiveMat } = useMemo(() => {
    const immersiveMat = new RawShaderMaterial({
      vertexShader: vUvPosVertex,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      opacity: 0,
      transparent: true,
      depthWrite: false,
      side: BackSide,
    });
    return { immersiveMat };
  }, []);

  useFrame(({ clock }) => {
    immersiveSphereRef.current.material.uniforms.uTime.value =
      clock.getElapsedTime();
  });

  useEffect(() => {
    if (isImmersive) {
      gsap.to(immersiveSphereRef.current.material.uniforms.uAlpha, {
        value: 1,
        duration: 1.3,
        ease: "power3.inOut",
        overwrite: true,
      });
    } else {
      gsap.to(immersiveSphereRef.current.material.uniforms.uAlpha, {
        value: 0,
        duration: 1.3,
        ease: "power3.inOut",
        overwrite: true,
      });
    }
  }, [isImmersive]);

  return (
    <group renderOrder={0}>
      <Sphere
        ref={immersiveSphereRef}
        scale={500}
        material={immersiveMat}
        rotation={[0, Math.PI / 2, 0]}
      />
    </group>
  );
};

export default memo(ImmersiveOrb);
