// React
import { Fragment, memo } from "react";

// Global State
import { useGlobalState } from "../../../state/useGlobalState";

// Lottie
import * as LottiePlayer from "@lottiefiles/lottie-player";

// Components
import Loader from "../1-Loader/Loader";
import Landing from "../2-Landing/Landing";
import Instruct from "../3-Instruct/Instruct";
import ARLaunch from "../4-ARLaunch/ARLaunch";

const HUD = () => {
  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);

  return (
    <Fragment>
      <ARLaunch />
      {(/Loader/gi.test(hudPhase) ||
        /Landing/gi.test(hudPhase) ||
        /Instruct/gi.test(hudPhase)) && <Instruct />}
      {(/Loader/gi.test(hudPhase) || /Landing/gi.test(hudPhase)) && <Landing />}
      {/Loader/gi.test(hudPhase) && <Loader />}
    </Fragment>
  );
};

export default memo(HUD);
