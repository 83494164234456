// React
import { Fragment, memo } from "react";

// Components
import Lighting from "./Lighting";
import Environment from "./Environment";

const Setting = () => {
  return (
    <Fragment>
      <Lighting />
      <Environment />
    </Fragment>
  );
};

export default memo(Setting);
