// Howler
import { Howl } from "howler";

const VOVolume = 1;
const HughsNetVO = {
  Intro: new Howl({ src: ["/audio/intro.aac"], volume: VOVolume }),
  Satellite: new Howl({ src: ["/audio/satellite.aac"], volume: VOVolume }),
  Gateway: new Howl({ src: ["/audio/gateway.aac"], volume: VOVolume }),
  House: new Howl({ src: ["/audio/home.aac"], volume: VOVolume }),
  SolarArray: new Howl({ src: ["/audio/solar_arrays.aac"], volume: VOVolume }),
  Bus: new Howl({ src: ["/audio/bus.aac"], volume: VOVolume }),
  Thrusters: new Howl({ src: ["/audio/thrusters.aac"], volume: VOVolume }),
  Reflectors: new Howl({ src: ["/audio/reflectors.aac"], volume: VOVolume }),
  StarTracker: new Howl({ src: ["/audio/star_tracker.aac"], volume: VOVolume }),
  Background: new Howl({
    src: ["/audio/background.aac"],
    volume: 0.4,
    loop: true,
  }),
};

const HughsNetVOKeys = Object.keys(HughsNetVO);
const HughsNetVOKey = HughsNetVOKeys[0];
const HughsNetVOCur = HughsNetVO[HughsNetVOKey];

const AudioModule = ({ set, get }) => {
  return {
    isFading: false,
    setIsFading: (isFading) => set({ isFading }),

    HughsNetVO,
    HughsNetVOKeys,
    HughsNetVOKey,
    HughsNetVOCur,
    playVO: (key) => {
      const targetVO = get().HughsNetVO[key];
      const currentlyPlaying = get().currentlyPlaying;
      const muteState = get().isGlobalMute;
      if (!targetVO.playing() && targetVO !== currentlyPlaying) {
        if (currentlyPlaying !== null) {
          currentlyPlaying.fade(1, 0, 800);
          setTimeout(() => {
            currentlyPlaying.stop();
            currentlyPlaying.volume(1);
          }, 800);
        }
        get().setCurrentlyPlaying(targetVO);
        setTimeout(() => {
          if (muteState) {
            targetVO.volume(0);
          }
          targetVO.play();
          get().setIsFading(false);
        }, 1500);
      }
    },

    currentlyPlaying: null,
    setCurrentlyPlaying: (currentlyPlaying) => set({ currentlyPlaying }),

    currentKey: null,
    setCurrentKey: (currentKey) => set({ currentKey }),

    isGlobalMute: false,
    setIsGlobalMute: (isGlobalMute) => set({ isGlobalMute }),

    setMuted: (key, muteState) => {
      if (key === "Background") {
        if (muteState) {
          get().HughsNetVO["Background"].fade(0.5, 0, 600);
        } else {
          get().HughsNetVO["Background"].fade(0, 0.5, 600);
        }
      } else {
        if (muteState) {
          get().HughsNetVO[key].fade(1, 0, 800);
        } else {
          get().HughsNetVO[key].fade(0, 1, 800);
        }
      }
    },

    playBackground: () => {
      get().HughsNetVO["Background"].play();
    },

    setStopAudio: (key) => {
      const fadeState = get().isFading;
      const setFadeState = get().setIsFading;
      const audioToStop = get().HughsNetVO[key];
      const muteState = get().isGlobalMute;
      if (muteState) {
        audioToStop.stop();
        audioToStop.volume(1);
      } else if (!fadeState && !muteState) {
        setFadeState(true);
        audioToStop.fade(1, 0, 800);
        setTimeout(() => {
          audioToStop.stop();
          audioToStop.volume(1);
          setFadeState(false);
        }, 800);
      }
    },
  };
};
export { AudioModule };
