// React
import { useRef, useMemo, useEffect, useState, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { Vector3, MathUtils, RawShaderMaterial } from "three";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Plane, Text } from "@react-three/drei";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a, config } from "@react-spring/three";

// WebGL
import { vUvPosVertex } from "../../4-WebGL/vertexProjections";

const Target = ({ touchTargetTexture }) => {
  // Refs
  const textRef = useRef();
  const reticleRef = useRef();
  const reticleTextRef = useRef();

  // Local State
  const [reticleDown, setReticleDown] = useState(false);

  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const setTapPosition = useGlobalState((state) => state.setTapPosition);
  const setReticleTapped = useGlobalState((state) => state.setReticleTapped);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);

  const uniforms = {
    uTime: { value: 0 },
    uAlpha: { value: 1 },
    uTexture: { value: touchTargetTexture },
  };

  const fragmentShader = `
  precision highp float;  

  varying vec3 vPos;
  varying vec2 vUv;

  uniform float uTime;
  uniform float uAlpha;
  uniform float uPulse;
  uniform sampler2D uTexture;

    float calcPulseWave(float dx) {
      return (dx - floor(dx));
    }

    float handlePulseWaveMask(vec2 uv, float startRadius, float rangeSize) {
      float ease = calcPulseWave(uTime * 0.8);
      float radius = startRadius + ease * rangeSize;
      vec2 toCenter = vec2(uv - 0.5);
      float dist = length(toCenter);
      float res = 190.0;
      float smoothness = 2.0 / res;
      float c = smoothstep(radius / res + smoothness, radius / res, dist);
      float opacity = (1.0 - smoothstep(0.1, 0.9, ease));
      return mix(0.0, 1.0, c * opacity);
    }
    
    void main() {
      vec2 sizedvUv = vUv / 2.0;
      float colorMap = texture2D(uTexture, vec2(sizedvUv.x + .375, sizedvUv.y + .625)).r;
      float pA = handlePulseWaveMask(vUv, 0.0, 55.0);
      float oA = 1.0 - step(1.0, length(vPos));
      float iA = step(0.8, length(vPos));
      float dot = smoothstep(0.15, length(vPos / smoothstep(2.0, 0.0, calcPulseWave(uTime * 0.8 - 0.2))), 0.0);
      float fA = colorMap *= oA *= iA += dot;
      vec3 fC = vec3(pA);
      gl_FragColor = vec4(vec3(1.0), fA += fC.r);
    }
`;

  const { reticleMat } = useMemo(() => {
    const reticleMat = new RawShaderMaterial({
      vertexShader: vUvPosVertex,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
    });
    return { reticleMat };
  }, []);

  // Target Geometry scale
  const [{ scale }, apiS] = useSpring(() => ({
    scale: 0,
  }));

  const { tapScale } = useSpring({
    config: config.wobbly,
    tapScale: reticleDown ? 0.6 : 1,
  });

  const [tPosition, tDirection] = useMemo(() => {
    const tPosition = new Vector3();
    const tDirection = new Vector3();
    return [tPosition, tDirection];
  }, []);

  useFrame(({ camera, clock }) => {
    if (!isScenePlaced) {
      tPosition.copy(camera.position);
      tPosition.y = -0.01;
      camera.getWorldDirection(tDirection);
      tDirection.y = 0;
      tDirection.normalize();
      tPosition.addScaledVector(
        tDirection,
        1 + MathUtils.clamp(camera.position.y, 1, 5)
      );
      reticleTextRef.current.position.lerp(tPosition, 0.03);
      reticleRef.current.material.uniforms.uTime.value = clock.getElapsedTime();

      textRef.current.lookAt(
        camera.position,
        textRef.current.position,
        textRef.current.up
      );
    }
  });

  useEffect(() => {
    if (/ARLaunch/gi.test(hudPhase)) {
      apiS.start({
        scale: 1,
      });
    }
  }, [hudPhase]);

  const tl = gsap.timeline();

  const handleReticleTap = (e, down) => {
    e.stopPropagation();
    setReticleDown(down);
    if (!down && reticleDown) {
      tl.to(reticleTextRef.current, {
        duration: 0.0001,
        overwrite: true,
        onComplete: () => {
          setTapPosition(reticleTextRef.current.position);
        },
      });
      tl.to(reticleTextRef.current.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.5,
        ease: "elastic.in(1, 1)",
        onComplete: () => {
          setReticleTapped(true);
          tl.kill();
          gsap.killTweensOf(reticleMat.uniforms.uAlpha);
        },
      });
      gsap.to(reticleMat.uniforms.uAlpha, {
        value: 0,
        duration: 0.2,
        overwrite: true,
      });
    }
  };

  const handlePointerOut = () => {
    setReticleDown(false);
  };

  const handlePointerDown = (e) => {
    handleReticleTap(e, true);
  };

  const handlePointerUp = (e) => {
    handleReticleTap(e, false);
  };

  return (
    <a.group
      ref={reticleTextRef}
      scale={scale}
      onPointerOut={handlePointerOut}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      <group renderOrder={1}>
        <Text
          ref={textRef}
          color="white"
          font={"/fonts/Gilroy-SemiBold.otf"}
          characters="aegprsto"
          position={[0, 2.5, 0]}
          scale={3}
        >
          Tap Target to Start
        </Text>
      </group>

      <a.group scale={tapScale} renderOrder={1}>
        <Plane
          ref={reticleRef}
          args={[4, 4]}
          material={reticleMat}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </a.group>
    </a.group>
  );
};

export default memo(Target);
