// React
import { useRef, useState, useEffect, memo } from "react";

// State
import { useGlobalState } from "../../../state/useGlobalState";

// GSAP
import gsap from "gsap";

// React Drei
import { useProgress } from "@react-three/drei";

// Styled Components
import styled, { keyframes } from "styled-components";

// Styles
import { PageContainer } from "../../../styles/Containers";
import { MarginPaddingNone } from "../../../styles/Attributes";

const Loader = () => {
  // Refs
  const gearsRef = useRef();
  const unmuteRef = useRef();
  const loadContainerRef = useRef();
  const unmuteDemoHandRef = useRef();

  // Global State
  const setHudPhase = useGlobalState((state) => state.setHudPhase);
  const setBodyColor = useGlobalState((state) => state.setBodyColor);
  const pipelineReady = useGlobalState((state) => state.pipelineReady);

  // Local State
  const [gearOne, setGearOne] = useState(false);
  const [gearTwo, setGearTwo] = useState(false);

  // Load State
  const { active, progress } = useProgress();

  const XR8Progress = pipelineReady ? 10 : 0;

  const globalProgress = progress * 0.9 + XR8Progress;

  const sceneLoaded = pipelineReady && globalProgress === 100 && !active;

  // Gsap
  const tl = gsap.timeline();

  // Functions
  const handleNextPhase = () => {
    gsap.to(unmuteRef.current, {
      y: -20,
      opacity: 0,
      overwrite: true,
      ease: "power3.inOut",
      onComplete: () => {
        setBodyColor(true);
        gsap.to(loadContainerRef.current, {
          opacity: 0,
          delay: 0.1,
          overwrite: true,
          ease: "power3.inOut",
          onComplete: () => {
            tl.kill();
            setHudPhase("Landing");
          },
        });
      },
    });
  };

  const runDemo = () => {
    tl.to(unmuteDemoHandRef.current, {
      x: 40,
      ease: "power3.inOut",
      duration: 1,
      delay: 0.5,
    });
    tl.to(unmuteDemoHandRef.current, {
      scale: 0.9,
      ease: "power3.inOut",
      duration: 0.1,
      onComplete: () => {
        gsap.to("#path", {
          fill: "#F54359",
          ease: "power3.inOut",
          duration: 0.2,
        });
      },
    });
    tl.to(unmuteDemoHandRef.current, {
      scale: 1,
      ease: "power3.inOut",
      duration: 0.1,
    });
    tl.to(unmuteDemoHandRef.current, {
      x: 0,
      ease: "power3.inOut",
      duration: 1,
      onComplete: () => {
        gsap.to("#path", {
          fill: "#458C27",
          ease: "power3.inOut",
          duration: 0.2,
        });
        runDemo();
      },
    });
  };

  const handleProceed = () => {
    gsap.to(gearsRef.current, {
      y: -20,
      opacity: 0,
      ease: "power3.inOut",
      overwrite: true,
      delay: 1,
      onComplete: () => {
        gsap.fromTo(
          unmuteRef.current,
          {
            y: 20,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            overwrite: true,
            ease: "power3.inOut",
            onComplete: () => {
              runDemo();
            },
          }
        );
      },
    });
  };

  const handleGearOne = () => {
    setGearOne(true);
  };

  const handleGearTwo = () => {
    setGearTwo(true);
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    handleNextPhase();
  };

  useEffect(() => {
    if (sceneLoaded) {
      handleProceed();
    }
    return () => {
      tl.kill();
      gsap.killTweensOf([
        gearsRef.current,
        unmuteRef.current,
        loadContainerRef.current,
        unmuteDemoHandRef.current,
      ]);
    };
  }, [sceneLoaded]);

  useEffect(() => {
    if (gearOne && gearTwo) {
      gsap.to(gearsRef.current, {
        opacity: 1,
        overwrite: true,
        ease: "power3.inOut",
      });
    }

    return () => {
      tl.kill();
      gsap.killTweensOf([
        gearsRef.current,
        unmuteRef.current,
        loadContainerRef.current,
        unmuteDemoHandRef.current,
      ]);
    };
  }, [gearOne, gearTwo]);

  return (
    <LoaderContainer ref={loadContainerRef}>
      <div className="gears-container" ref={gearsRef} style={{ opacity: 0 }}>
        <BigGear>
          <img
            draggable="false"
            src="/ui/0-loader/gear.svg"
            alt="loading"
            onLoad={handleGearOne}
          />
        </BigGear>
        <LilGear>
          <img
            draggable="false"
            src="/ui/0-loader/gear.svg"
            alt="loading"
            onLoad={handleGearTwo}
          />
        </LilGear>
      </div>
      <div ref={unmuteRef} className="unmute">
        <div className="unmute-anim">
          <img
            ref={unmuteDemoHandRef}
            className="unmute-demo-hand"
            draggable="false"
            src={`/ui/0-loader/unmute-hand.svg`}
            alt={"unmute-demo-hand"}
          />
          <svg
            className="unmute-demo-phone"
            width="134"
            height="254"
            viewBox="0 0 134 254"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M133.371 126.924C133.371 162.217 133.371 197.512 133.371 232.808C133.371 237.862 132.051 242.448 128.819 246.392C124.695 251.421 119.36 253.995 112.861 254C84.7731 254 56.6914 254 28.6162 254C19.3197 254 11.7081 248.251 8.90973 239.386C8.32977 237.287 8.0788 235.112 8.16581 232.936C8.16581 177.694 8.17734 122.453 8.20033 67.214C8.20033 65.9419 7.90463 65.4932 6.61878 65.646C2.9386 66.0849 0.657571 63.0476 0.706837 59.7737C0.820149 53.1272 0.741357 46.4611 0.741357 39.8294C0.741357 35.8849 2.77116 33.7944 6.69275 33.8831C7.93426 33.9127 8.22493 33.5922 8.17074 32.3645C8.08206 28.4693 8.13622 24.5692 8.14115 20.6691C8.14115 11.6707 12.5751 5.45325 20.4577 1.59751C23.1821 0.276113 26.1381 0 29.1237 0C56.8802 0 84.6335 0 112.384 0C121.251 0 127.597 4.08254 131.533 11.9764C132.977 14.8707 133.356 18.0016 133.356 21.1819C133.373 56.4225 133.377 91.6698 133.371 126.924Z"
              fill="#FEFEFE"
            />
            <path
              d="M127.538 234.85C127.439 241.176 121.823 247.29 115.315 248.128C114.335 248.259 113.348 248.328 112.359 248.335C84.5992 248.335 56.8459 248.335 29.0991 248.335C23.2315 248.335 18.7828 245.87 15.6938 240.895C14.6362 239.127 14.0162 237.132 13.8857 235.077C13.8167 234.302 13.8118 233.518 13.8118 232.739C13.8118 162.229 13.8019 91.7209 13.7822 21.2166C13.7822 16.1183 15.4376 11.9174 19.4626 8.74213C21.6939 6.9084 24.4606 5.85098 27.3452 5.72953C29.5425 5.5471 31.7497 5.67036 33.9519 5.65064C37.0163 5.65064 40.0806 5.69995 43.1449 5.62106C44.3273 5.58654 44.9628 6.01551 45.3323 7.10024C45.5787 7.8349 45.9827 8.51532 46.229 9.24998C47.0123 11.5822 48.3277 13.3917 50.7812 14.1806C51.0835 14.4581 51.3411 14.7808 51.5448 15.1371C61.2503 29.6232 70.9951 44.0863 80.7793 58.5264C91.5719 74.5476 102.378 90.5638 113.197 106.575C117.783 113.379 122.335 120.203 126.991 126.958C127.464 127.69 127.687 128.555 127.627 129.424C127.627 145.596 127.627 161.767 127.627 177.936C127.627 196.485 127.627 215.034 127.627 233.583C127.674 234.007 127.644 234.436 127.538 234.85Z"
              fill="#004438"
            />
            <path
              d="M127.538 234.85C127.538 234.233 127.503 233.617 127.503 233.006C127.503 198.541 127.503 164.079 127.503 129.621C127.53 128.764 127.283 127.922 126.799 127.215C122.529 120.969 118.294 114.707 114.093 108.429C109.773 102.019 105.477 95.6096 101.146 89.1998C96.6825 82.6026 92.1895 76.0203 87.726 69.4231C83.4562 63.1251 79.2046 56.8254 74.971 50.5241C70.9574 44.5548 66.9487 38.5822 62.945 32.6063C59.0694 26.8441 55.189 21.0819 51.3035 15.3196C51.0966 15.0189 50.9241 14.6934 50.7319 14.3779C51.3576 13.8158 52.1311 13.9982 52.8504 13.9933C65.3936 13.9933 77.9385 13.9933 90.4849 13.9933C91.2632 14.0214 92.0423 13.9701 92.8103 13.8404C93.5009 13.7269 94.1564 13.4569 94.7268 13.0509C95.2972 12.645 95.7673 12.114 96.1012 11.4984C96.9683 9.91569 97.7713 8.29845 98.5349 6.66149C98.7813 6.13885 99.0276 5.62113 99.7469 5.63099C104.417 5.69016 109.108 5.53238 113.758 5.71481C120.527 5.71481 127.129 11.7301 127.553 18.7563C127.637 20.1516 127.696 21.5371 127.696 22.9325C127.696 57.6834 127.696 92.4326 127.696 127.18C127.696 162.352 127.696 197.512 127.696 232.661C127.735 233.395 127.853 234.145 127.538 234.85Z"
              fill="#0A5447"
            />
            <path
              d="M113.768 5.71947C109.388 5.71947 105.008 5.78357 100.628 5.75399C99.6775 5.75399 99.2243 5.99066 98.8351 6.93733C98.0802 8.79732 97.1635 10.5874 96.0959 12.287C95.081 13.8796 93.3665 14.3825 91.5141 14.3825H50.7217C47.5391 13.8155 46.6129 11.2072 45.603 8.69262C44.4304 5.76878 44.4452 5.76878 41.3168 5.73426L27.3301 5.69482C27.9853 5.35461 28.6948 5.4976 29.3796 5.4976C48.3438 5.4976 67.3047 5.51074 86.2624 5.53704C94.9037 5.53704 103.545 5.53704 112.186 5.51239C112.728 5.54197 113.275 5.42364 113.768 5.71947Z"
              fill="#A4A4A4"
            />
            <path
              id="path"
              d="M8.18583 49.7106C8.18583 53.1127 8.15135 56.5099 8.18583 59.912C8.18583 60.7995 7.92473 60.9869 7.0872 60.977C4.42683 60.9475 4.42676 60.977 4.42676 58.2899C4.42676 52.102 4.42676 45.914 4.42676 39.7261C4.42676 38.8337 4.55491 38.4491 5.59442 38.4738C8.17597 38.5477 8.1809 38.4738 8.1809 41.1067L8.18583 49.7106Z"
              fill="#458C27"
            />
          </svg>
        </div>

        <h2 className="unmute-text">Umute audio for best experience</h2>

        <button className="unmute-button" onClick={handleOnClick}>
          <img draggable="false" src="/ui/0-loader/arrow.svg" alt="proceed" />
        </button>
      </div>
    </LoaderContainer>
  );
};

export default memo(Loader);

const LoaderContainer = styled(PageContainer)`
  background-color: #004438;
  .gears-container {
    ${MarginPaddingNone};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 15rem;
  }

  .unmute {
    ${MarginPaddingNone};
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .unmute-anim {
      ${MarginPaddingNone};
      width: 15rem;
      height: 18rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .unmute-demo-hand {
        ${MarginPaddingNone};
        width: 4rem;
        padding-top: 2rem;
        margin-right: 2rem;
      }
    }
    .unmute-text {
      ${MarginPaddingNone};
      width: 17rem;
      font-family: "GilroyRegular";
      text-align: center;
      margin-bottom: 2rem;
    }
    .unmute-button {
      ${MarginPaddingNone};
      border: none;
      background: none;
      width: 12rem;
      height: 3rem;
      background-color: #339966;
      border-radius: 1.5rem;
      transition: all 0.2s ease-in-out;
      img {
        padding-top: 0.2rem;
        width: 2rem;
      }
    }

    .unmute-button:active {
      transform: scale(0.85);
    }
  }
`;

const PlayContainer = styled.div`
  ${MarginPaddingNone};
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
`;

const lilSpin = keyframes`
   0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 50deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const bigSpin = keyframes`
   0% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);

  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
`;

const BigGear = styled(PlayContainer)`
  ${MarginPaddingNone};
  animation: ${bigSpin} 7s linear infinite;
  margin-left: 2.8rem;
  margin-bottom: 2.4rem;
  width: 5.5rem;
`;

const LilGear = styled(PlayContainer)`
  ${MarginPaddingNone};
  animation: ${lilSpin} 7s linear infinite;
  margin-right: 4.8rem;
  margin-top: 2.7rem;
  width: 3.9rem;
`;
