const transmissionData = [
  {
    id: 0,
    scale: [0.6, 0.7, 0.6],
    position: [1.79, 3.68, 2.6],
    rotation: [1.34, 0.03, 5.94],
  },
  {
    id: 1,
    scale: [0.6, 0.7, 0.6],
    position: [1.76, 3.69, 2.74],
    rotation: [1.33, 0.01, 2.8],
  },
  {
    id: 2,
    scale: [0.6, 0.75, 0.6],
    position: [1.22, 4.15, 2.6],
    rotation: [1, 0, 2.53],
  },
  {
    id: 3,
    scale: [0.6, 0.75, 0.6],
    position: [1.33, 4.2, 2.6],
    rotation: [1, 0, 2.53 + Math.PI],
  },
];

export { transmissionData };
