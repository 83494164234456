// React
import { useRef, useMemo, memo } from "react";

// Three
import { RawShaderMaterial } from "three";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Circle } from "@react-three/drei";

// WebGL
import { vUvVertex } from "../../4-WebGL/vertexProjections";

const Globe = ({ nodes, materials, cloudTexture }) => {
  // Refs
  const cloudsRef = useRef();
  const fakeBloomRef = useRef();

  const atmosphereUniforms = {
    uTime: { value: 0 },
  };

  const atmosphereFragmentShader = `
    precision highp float;

    varying vec2 vUv;
    
    void main() {
      float dist = length(vUv - 0.5);
      float m  = 1.0 - smoothstep(0.3, 0.5, dist) / 2.0;
      float s = smoothstep(0.6, 1.0, m);
      float oA = step(0.3, length(vUv - 0.5));
      gl_FragColor = vec4(vec3(m * 0.45, m * 0.65, m * 2.0) * 1.1, s *= oA * 0.5);
    }
  `;

  const cloudUniforms = {
    uTime: { value: 0 },
    uTexture: { value: cloudTexture },
  };

  const cloudFragmentShader = `
    precision highp float;  

    varying vec3 vPos;
    varying vec2 vUv;

    uniform sampler2D uTexture;
    uniform float uTime;

    void main() {
      float colorMapAlpha = texture2D(uTexture, vUv).r;
      gl_FragColor = vec4(vec3(1.0), colorMapAlpha);
    }
  `;

  // Shader Material
  const { fakeBloomMat, cloudMat } = useMemo(() => {
    const fakeBloomMat = new RawShaderMaterial({
      vertexShader: vUvVertex,
      fragmentShader: atmosphereFragmentShader,
      uniforms: atmosphereUniforms,
      transparent: true,
      depthWrite: false,
    });

    const cloudMat = new RawShaderMaterial({
      vertexShader: vUvVertex,
      fragmentShader: cloudFragmentShader,
      uniforms: cloudUniforms,
      transparent: true,
      depthWrite: false,
    });

    return { fakeBloomMat, cloudMat };
  }, []);

  useFrame(({ camera, clock }) => {
    cloudsRef.current.rotation.y += 0.0003;
    cloudMat.uniforms.uTime.value = clock.getElapsedTime();
    fakeBloomRef.current.lookAt(
      camera.position,
      fakeBloomRef.current.position,
      fakeBloomRef.current.up
    );
  });

  return (
    <group dispose={null} scale={2} rotation={[0, -2.826, 0]}>
      <Circle
        scale={1.45}
        renderOrder={1}
        ref={fakeBloomRef}
        material={fakeBloomMat}
        position={[-0.02, 1.05, 0]}
        args={[1, 64, 0, Math.PI * 2]}
      />

      <mesh
        ref={cloudsRef}
        renderOrder={2}
        material={cloudMat}
        geometry={nodes.globe_atmosphere001.geometry}
      />

      <mesh
        geometry={nodes.globe_atmosphere001_1.geometry}
        material={materials["globe_mat.003"]}
      />
    </group>
  );
};

export default memo(Globe);
