// React
import { useRef, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../state/useGlobalState";

// React Three Fiber
import { useThree } from "@react-three/fiber";

// Use Gesture
import { useGesture } from "@use-gesture/react";

// React Spring
import { useSpring, a, config } from "@react-spring/three";

const ARInteractionControls = ({ children }) => {
  // Refs
  const globalInteractionRef = useRef();

  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const tapPosition = useGlobalState((state) => state.tapPosition);
  const reticleTapped = useGlobalState((state) => state.reticleTapped);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);
  const setIsScenePlaced = useGlobalState((state) => state.setIsScenePlaced);

  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  useEffect(() => {
    if (reticleTapped && tapPosition) {
      if (tapPosition.x === 0 && tapPosition.z === 0) {
        setIsScenePlaced(true);
      } else {
        setGestureSpring.start({
          duration: 1,
          config: {
            clamp: true,
            precision: 0.000001,
          },
          immediate: true,
          position: [tapPosition.x, 0, tapPosition.z],
          onRest: () => {
            setIsScenePlaced(true);
          },
        });
      }
    }
  }, [reticleTapped]);

  // Three State
  const { size, viewport, gl } = useThree();

  // Aspect/Viewport
  const aspect = size.width / viewport.width;

  // Animation State
  const [{ scale, position, rotation }, setGestureSpring] = useSpring(() => ({
    scale: 1,
    position: [tapPosition.x, 0, tapPosition.z],
    rotation: [0, 0, 0],
    config: config.slow,
  }));

  // Gesture State
  useGesture(
    {
      onDrag: ({ down, offset: [ox, oy], pinching, cancel, touches }) => {
        if (
          pinching ||
          !/ARLaunch/gi.test(hudPhase) ||
          !isScenePlaced ||
          touches !== 1
        ) {
          return cancel();
        }

        if (down) {
          setGestureSpring.start({
            position: [
              ox / aspect + tapPosition.x,
              0,
              oy / aspect + tapPosition.z,
            ],
          });
        }
      },

      onPinch: ({ down, offset: [s, a], cancel, touches, pinching }) => {
        if (
          !pinching ||
          !/ARLaunch/gi.test(hudPhase) ||
          !isScenePlaced ||
          touches !== 2
        ) {
          return cancel();
        }
        if (down) {
          setGestureSpring.start({
            scale: s,
            rotation: [0, a * -0.1, 0],
          });
        }
      },
    },
    {
      target: gl.domElement,
      eventOptions: {
        passive: false,
      },
      drag: {
        filterTaps: true,
        bounds: { left: -500, right: 500, top: -500, bottom: 500 },
        rubberband: true,
      },
      pinch: { scaleBounds: { min: 0.5, max: 1.3 }, rubberband: true },
    }
  );

  return (
    <a.group
      scale={scale}
      position={position}
      rotation={rotation}
      ref={globalInteractionRef}
    >
      {children}
    </a.group>
  );
};

export default memo(ARInteractionControls);
