// React
import { useRef, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../state/useGlobalState";

// Three
import { Matrix4, Euler } from "three";

// React Three Fiber
import { useThree } from "@react-three/fiber";

// Drei
import { useGLTF, useTexture } from "@react-three/drei";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a, easings } from "@react-spring/three";

// Models
import Globe from "../2-Assets/0-Globe/Globe";
import House from "../2-Assets/1-House/House";
import Gateway from "../2-Assets/2-Gateway/Gateway";
import Satellite from "../2-Assets/5-Satellite/Satellite";
import TouchPoints from "../2-Assets/3-TouchPoints/TouhPoints";
import IonThruster from "../2-Assets/6-IonThruster/IonThruster";
import Transmissions from "../2-Assets/4-Transmissions/Transmissions";
import Immersive from "../2-Assets/8-immersive/Immersive";

// Controls
import ARInteractionControls from "../3-Controls/ARInteractionControls";

const SceneStage = () => {
  // Refs
  const globalRef = useRef();
  const satelliteRef = useRef();

  // Global State
  const view = useGlobalState((state) => state.view);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);
  const setTouchTargetTexture = useGlobalState(
    (state) => state.setTouchTargetTexture
  );

  // Load Models
  const satelliteModel = useGLTF("/models/satellite.glb");
  const globeModel = useGLTF("/models/globe_v4_2048.glb");
  const houseModel = useGLTF("/models/house_v3_512_unlit.glb");
  const gatewayModel = useGLTF("/models/gateway_v6_512.glb");

  // Load Textures
  const cloudTexture = useTexture(
    "/textures/globe/cloud_texture.jpg",
    (texture) => {
      texture.flipY = false;
    }
  );

  const immersiveAtlas = useTexture(
    "/textures/immersiveDome/immersiveDome.jpg"
  );

  const touchPointAtlas = useTexture(
    "/textures/touchpoints/touchpoint_atlas_2048.jpg"
  );

  // Three
  const { camera } = useThree();
  const globalLookAtEuler = new Euler();
  const globalLookAtMatrix = new Matrix4();
  const satelliteLookAtEuler = new Euler();
  const satelliteLookAtMatrix = new Matrix4();

  // Handle Rotate Global View
  const handleGlobalLookAt = (offset = 0) => {
    const globalLookAtCameraMatrix = globalLookAtMatrix.lookAt(
      camera.position,
      globalRef.current.position,
      globalRef.current.up
    );

    const globalLookAtCameraEuler = globalLookAtEuler.setFromRotationMatrix(
      globalLookAtCameraMatrix
    );

    gsap.to(globalRef.current.rotation, {
      y: globalLookAtCameraEuler._z + offset,
      ease: "power3.inOut",
      duration: 3,
      overwrite: true,
    });
  };

  // Handle Rotate Satellit View
  const handleSatelliteLookAt = (offset = 0) => {
    const satelliteLookAtCameraMatrix = satelliteLookAtMatrix.lookAt(
      camera.position,
      globalRef.current.position,
      globalRef.current.up
    );

    const satelliteLookAtCameraEuler =
      satelliteLookAtEuler.setFromRotationMatrix(satelliteLookAtCameraMatrix);

    gsap.to(satelliteRef.current.rotation, {
      y: satelliteLookAtCameraEuler._z + offset,
      ease: "power3.inOut",
      duration: 3,
      overwrite: true,
    });
  };

  const { satelliteAnimScale } = useSpring({
    config: { duration: 1800, easing: easings.easeInOutQuad },
    delay: 800,
    satelliteAnimScale:
      /Global/gi.test(view) ||
      /Gateway/gi.test(view) ||
      /House/gi.test(view) ||
      /Reflectors/gi.test(view)
        ? [1, 1, 1]
        : [1.5, 1.5, 1.5],
  });

  useEffect(() => {
    if (isScenePlaced) {
      if (/House/gi.test(view)) {
        handleGlobalLookAt();
      } else if (/Gateway/gi.test(view)) {
        handleGlobalLookAt(-1);
      } else if (/Satellite/gi.test(view)) {
        handleGlobalLookAt(-0.3);
      }
    }
  }, [view, isScenePlaced]);

  useEffect(() => {
    if (isScenePlaced) {
      if (
        /Global/gi.test(view) ||
        /Gateway/gi.test(view) ||
        /House/gi.test(view) ||
        /Reflectors/gi.test(view)
      ) {
        gsap.to(satelliteRef.current.rotation, {
          x: -0.19,
          y: Math.PI * 2 - 5.9,
          z: 0,
          ease: "power3.inOut",
          duration: 3,
          overwrite: true,
        });
      } else {
        if (/Thrusters/gi.test(view)) {
          handleSatelliteLookAt();
        } else {
          handleSatelliteLookAt(Math.PI);
        }
      }
    }
  }, [isScenePlaced, view]);

  useEffect(() => {
    if (touchPointAtlas) {
      setTouchTargetTexture(touchPointAtlas);
    }
  }, [touchPointAtlas]);

  useEffect(() => {
    if (isScenePlaced) {
      gsap.to(globalRef.current.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.7,
        ease: "power3.inOut",
      });
    }
  }, [isScenePlaced]);

  return (
    <ARInteractionControls>
      <group position={[0, -1, 0]}>
        <Immersive immersiveAtlas={immersiveAtlas} />
        <group ref={globalRef} scale={[0, 0, 0]}>
          <House nodes={houseModel.nodes} materials={houseModel.materials} />
          <Globe
            nodes={globeModel.nodes}
            materials={globeModel.materials}
            cloudTexture={cloudTexture}
          />
          <Gateway
            nodes={gatewayModel.nodes}
            materials={gatewayModel.materials}
          />
          <Transmissions />
          <TouchPoints viewType={"global"} touchPointAtlas={touchPointAtlas} />
          <a.group
            ref={satelliteRef}
            position={[2.09, 4.3, 3.5]}
            scale={satelliteAnimScale}
          >
            <group rotation={[0.19, 6, 0.09]}>
              <Satellite
                nodes={satelliteModel.nodes}
                materials={satelliteModel.materials}
              />
              <IonThruster />
              <TouchPoints
                viewType={"satellite"}
                touchPointAtlas={touchPointAtlas}
              />
            </group>
          </a.group>
        </group>
      </group>
    </ARInteractionControls>
  );
};

useGLTF.preload("/models/satellite.glb");
useGLTF.preload("/models/globe_v4_2048.glb");
useGLTF.preload("/models/house_v3_512_unlit.glb");
useGLTF.preload("/models/gateway_v6_512.glb");

export default memo(SceneStage);
