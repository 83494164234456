// React
import { forwardRef, memo } from "react";

// Styled Components
import styled from "styled-components";

// Styles
import { MarginPaddingNone } from "../../../styles/Attributes";

const Instruction = forwardRef(({ copy, name }, ref) => {
  return (
    <InstructionContainer ref={ref}>
      <img
        draggable="false"
        className={`${name}`}
        src={`/ui/2-instruct/${name}.svg`}
        alt={`${name}`}
        width="100%"
        height="100%"
      />
      <h3>{copy}</h3>
    </InstructionContainer>
  );
});

export default memo(Instruction);

const InstructionContainer = styled.div`
  opacity: 0;
  ${MarginPaddingNone}
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    ${MarginPaddingNone}
    width: 3.5rem;
  }

  .move {
    padding-left: 0.7rem;
  }

  h3 {
    ${MarginPaddingNone}
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-family: "GilroySemiBold";
    font-size: 1rem;
  }
`;
