// React
import { useRef, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Drei
import { Instances, Instance } from "@react-three/drei";

// GSAP
import gsap from "gsap";

// Data
import { gatewayData } from "./GatewayData";

const Gateway = ({ nodes, materials }) => {
  // Refs
  const gatewayDishRef = useRef();

  // Global State
  const view = useGlobalState((state) => state.view);

  useEffect(() => {
    if (/Gateway/gi.test(view)) {
      gsap.to(gatewayDishRef.current.rotation, {
        x: 5.7,
        ease: "back.inOut(5)",
        yoyo: true,
        repeat: -1,
        duration: 2,
        overwrite: true,
      });
    } else if (gatewayDishRef.current.rotation.x !== Math.PI * 2) {
      gsap.to(gatewayDishRef.current.rotation, {
        x: Math.PI * 2,
        ease: "power3.inOut",
        duration: 2,
        overwrite: true,
      });
    }
  }, [view]);

  return (
    <group scale={0.03} position={[1.2, 3.21, 1.12]} rotation={[0.7, 0, -0.6]}>
      <mesh
        geometry={nodes.base.geometry}
        material={materials.gateway_mat}
        position={[0.03, 0, 0]}
        rotation={[0, 0.42, 0]}
      />
      <Instances
        geometry={nodes.dish.geometry}
        material={materials.gateway_mat}
      >
        {gatewayData.map(({ id, scale, rotation, position }) => {
          return (
            <Instance
              key={id}
              ref={id === 0 ? gatewayDishRef : null}
              scale={scale}
              rotation={rotation}
              position={position}
            />
          );
        })}
      </Instances>
    </group>
  );
};

export default memo(Gateway);
