// Use Asset
import { useAsset } from "use-asset";

const getXR8 = () => window.XR8 || null;
const getXR8iFrame = () => window.iframeInnerPipelineModule || null;

const touchSupport = "ontouchend" in document;

const loadXR8 = async (APIKEY) => {
  if (touchSupport) {
    const SRC = `//apps.8thwall.com/xrweb?appKey=${APIKEY}`;
    await loadScript(SRC);

    const XR8 = getXR8();
    const iframeInnerPipelineModule = getXR8iFrame();

    if (XR8 && iframeInnerPipelineModule) {
      return { XR8, iframeInnerPipelineModule };
    } else {
      await new Promise((resolve) =>
        window.addEventListener("xrloaded", resolve)
      );
      return { XR8: getXR8(), iframeInnerPipelineModule: getXR8iFrame() };
    }
  }
};

const loadScript = (SRC) =>
  new Promise((resolve, reject) => {
    const SCRIPT = document.createElement("script");
    SCRIPT.addEventListener("load", resolve);
    SCRIPT.addEventListener("error", reject);
    SCRIPT.src = SRC;
    document.body.appendChild(SCRIPT);
  });

const useXR8 = (XR8APIKEY) => {
  const XR8Assets = useAsset(loadXR8, XR8APIKEY);
  return XR8Assets;
};

export { useXR8 };
