// React
import { useEffect, memo } from "react";

// State
import { useGlobalState } from "../../state/useGlobalState";

// Three
import { MathUtils, sRGBEncoding, ACESFilmicToneMapping } from "three";

// R3F
import { Canvas } from "@react-three/fiber";

// Resize Polyfill
import { ResizeObserver } from "@juggle/resize-observer";

// XR8
import XR8Controls from "../1-XR8/XR8Controls";

// Scene
import Scene from "./0-Scene/Scene";
import SceneStage from "./0-Scene/SceneStage";
import Reticle from "./2-Assets/7-Reticle/Recticle";

// Setting
import Setting from "./1-Setting/Setting";

const rendererParams = {
  alpha: false,
  stencil: false,
  antialias: true,
  autoClear: false,
  outputEncoding: sRGBEncoding,
  toneMapping: ACESFilmicToneMapping,
  debug: { checkShaderErrors: false },
  powerPreference: "high-performance",
  preserveDrawingBuffer: false,
};

const R3FXR8Canvas = ({ XR8, iframeInnerPipelineModule }) => {
  // Global State
  const setXR8 = useGlobalState((state) => state.setXR8);
  const setPipelineReady = useGlobalState((state) => state.setPipelineReady);
  const touchTargetTexture = useGlobalState(
    (state) => state.touchTargetTexture
  );

  useEffect(() => {
    if (XR8) {
      setXR8(XR8);
    }
  }, [XR8]);

  const onPipelineReady = () => {
    setPipelineReady(true);
  };

  return (
    <Canvas
      onCreated={({ gl }) => {
        gl.XR8Started = false;
      }}
      gl={rendererParams}
      camera={{ position: [0, 6, 6] }}
      resize={{ polyfill: ResizeObserver }}
      dpr={MathUtils.clamp(window.devicePixelRatio, 1, 2)}
    >
      {XR8 && iframeInnerPipelineModule && (
        <XR8Controls
          XR8={XR8}
          onPipelineReady={onPipelineReady}
          iframeInnerPipelineModule={iframeInnerPipelineModule}
        />
      )}
      <Scene>
        <Setting />
        <SceneStage />
        {touchTargetTexture && (
          <Reticle touchTargetTexture={touchTargetTexture} />
        )}
      </Scene>
    </Canvas>
  );
};
export default memo(R3FXR8Canvas);
