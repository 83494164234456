// React
import { useEffect, memo } from "react";

// Global State
import { useGlobalState } from "./state/useGlobalState";

// React Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// React Device Detect
import { isDesktop } from "react-device-detect";

// Page Routes
import { PageRoutes } from "./routes/PageRoutes";

// Style Provider
import StyleProvider from "./styles/StyleProvider";

const R3FXR8App = () => {
  // Global State
  const setMuted = useGlobalState((state) => state.setMuted);
  const setHudPhase = useGlobalState((state) => state.setHudPhase);
  const HughsNetVOKeys = useGlobalState((state) => state.HughsNetVOKeys);

  const gestureHandler = (e) => e.preventDefault();

  const onBlur = () => {
    HughsNetVOKeys.forEach((key) => {
      setMuted(key, true);
    });
  };

  const onFocus = () => {
    HughsNetVOKeys.forEach((key) => {
      setMuted(key, false);
    });
  };

  const onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);
      window.oncontextmenu = onContextMenu;
    }

    if (document) {
      document.addEventListener("gesturestart", gestureHandler);
      document.addEventListener("gesturechange", gestureHandler);
      document.addEventListener("gestureend", gestureHandler);
    }

    if (isDesktop) {
      setHudPhase("Landing");
    }

    return () => {
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("focus", onFocus);
      document.removeEventListener("gesturestart", gestureHandler);
      document.removeEventListener("gesturechange", gestureHandler);
      document.removeEventListener("gestureend", gestureHandler);
    };
  }, [window, document, isDesktop]);

  return (
    <StyleProvider>
      <BrowserRouter>
        <Routes>
          {PageRoutes.map(({ id, path, component }) => (
            <Route exact key={id} path={path} element={component} />
          ))}
        </Routes>
      </BrowserRouter>
    </StyleProvider>
  );
};

export default memo(R3FXR8App);
