const map = `
    float map(float value, float min1, float max1, float min2, float max2) {
        return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
    }
`;

const permute = `
    vec4 permute(vec4 x) { 
        return mod(((x * 34.0) + 1.0) * x, 289.0); 
    }
`;

const taylorInvSqrt = `
    vec4 taylorInvSqrt(vec4 r) { 
        return 1.79284291400159 - 0.85373472095314 * r; 
    }
`;

const fade = `
    vec3 fade(vec3 t) { 
        return t * t * t * (t * (t * 6.0 - 15.0) + 10.0); 
    }
`;

const pNormalize = `
    float pNormalize(float v) { 
        return map(v, -1.0, 1.0, 0.0, 1.0); 
    }
`;

export { map, permute, taylorInvSqrt, fade, pNormalize };
