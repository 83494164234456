// React
import { StrictMode } from "react";

// React Dom
import { createRoot } from "react-dom/client";

// App
import R3FXR8App from "./R3FXR8App";

// Root Element
const rootElement = createRoot(document.getElementById("root"));

rootElement.render(
  <StrictMode>
    <R3FXR8App />
  </StrictMode>
);
