// Three
import { Vector3 } from "three";

const InteractionModule = ({ set, get }) => {
  return {
    hideInfoBox: false,
    setHideInfoBox: (hideInfoBox) => set({ hideInfoBox }),

    rotate: false,
    setRotate: (rotate) => set({ rotate }),

    isScenePlaced: false,
    setIsScenePlaced: (isScenePlaced) => set({ isScenePlaced }),

    reticleTapped: false,
    setReticleTapped: (reticleTapped) => set({ reticleTapped }),

    tapPosition: new Vector3(0, 0, 0),
    setTapPosition: (tapPosition) => set({ tapPosition }),

    isImmersive: false,
    setIsImmersive: (isImmersive) => set({ isImmersive }),

    satelliteAlreadyViewed: false,
    setSatelliteAlreadyViewed: (satelliteAlreadyViewed) => {
      set({ satelliteAlreadyViewed });
    },

    isTouchpointInstruction: true,
    setIsTouchpointInstruction: (isTouchpointInstruction) =>
      set({ isTouchpointInstruction }),

    isDragPointerDown: false,
    setIsDragPointerDown: (isDragPointerDown) => set({ isDragPointerDown }),
  };
};
export { InteractionModule };
