// React
import { useRef, useState, useMemo, useEffect, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { RawShaderMaterial } from "three";

// WebGL
import { vUvPosVertex } from "../../4-WebGL/vertexProjections";

// React Three Fiber
import { useFrame } from "@react-three/fiber";

// Drei
import { Circle, Line } from "@react-three/drei";

// React Spring
import { useSpring, a } from "@react-spring/three";

// Gsap
import gsap from "gsap";

const TouchPoint = ({
  name,
  onTap,
  position,
  rotation,
  stemLength,
  atlasShiftX,
  atlasShiftY,
  touchPointColor,
  touchPointAtlas,
}) => {
  // Refs
  const touchPointRef = useRef();
  const isDownRef = useRef(false);

  // Global State
  const view = useGlobalState((state) => state.view);
  const isFading = useGlobalState((state) => state.isFading);
  const visitedViews = useGlobalState((state) => state.visitedViews);
  const isScenePlaced = useGlobalState((state) => state.isScenePlaced);

  // Local State
  const [visibility, setVisibility] = useState(false);

  // Shader Attributes
  const uniforms = {
    uTime: { value: 0 },
    uBracket: { value: 0.22 },
    uTexture: { value: touchPointAtlas },
    uAtlasShiftX: { value: atlasShiftX },
    uAtlasShiftY: { value: atlasShiftY },
    uTouchPointColor: { value: touchPointColor },
  };

  const fragmentShader = `
    precision highp float;  

    varying vec3 vPos;
    varying vec2 vUv;

    uniform float uTime;
    uniform float uBracket;
    uniform sampler2D uTexture;
    uniform float uAtlasShiftX;
    uniform float uAtlasShiftY;
    uniform vec3 uTouchPointColor;

      float calcPulseWave(float dx) {
        return (dx - floor(dx));
      }

      float handlePulseWaveMask(vec2 uv, float startRadius, float rangeSize) {
        float time = 0.5 + uTime * 0.5;
        float ease = calcPulseWave(0.5 + time);
        float radius = startRadius + ease * rangeSize;
        vec2 toCenter = vec2(uv - 0.5);
        float dist = length(toCenter);
        float res = 190.0;
        float smoothness = 2.0 / res;
        float c = smoothstep(radius / res + smoothness, radius / res, dist);
        float opacity = (1.0 - smoothstep(0.1, 0.9, ease));
        return mix(0.0, 1.0, c * opacity);
      }
      
      void main() {
        vec2 sizedUV = vUv / 2.0;
        vec3 colorMap = texture2D(uTexture,  vec2(sizedUV.x - uAtlasShiftX, sizedUV.y + uAtlasShiftY)).rgb;
        float colorMapAlpha = texture2D(uTexture, vec2(sizedUV.x + 0.375, sizedUV.y + 0.625)).r;
        float pulseWaveAlpha = handlePulseWaveMask(vUv, 30.0, 60.0) * 1.8;
        float circleMaskAlpha = 1.0 - step(uBracket, length(vPos));
        float colorMapMaskAlpha = colorMapAlpha *= circleMaskAlpha;
        vec3 finalColor = mix(uTouchPointColor, colorMap, colorMapMaskAlpha);
        float finalAlpha = pulseWaveAlpha += colorMapMaskAlpha;
        gl_FragColor = vec4(finalColor, finalAlpha);
      }
  `;

  // Shader Material
  const { touchPointMat } = useMemo(() => {
    const touchPointMat = new RawShaderMaterial({
      vertexShader: vUvPosVertex,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
    });
    return { touchPointMat };
  }, []);

  useFrame(({ camera }) => {
    touchPointRef.current.lookAt(
      camera.position,
      touchPointRef.current.position,
      touchPointRef.current.up
    );
  });

  // Animations
  const touchPointSptingConfig = {
    mass: 1,
    tension: 180,
    friction: 12,
    precision: 0.03,
  };

  const [{ touchPointScale }, setTouchPointSpring] = useSpring(() => ({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    touchPointScale: 0.65,
  }));

  const [touchPointAndStem, apiTouchPointAndStem] = useSpring(() => ({
    scale: 0,
  }));

  const handleTouchPointTap = (e) => {
    e.stopPropagation();
    const down = e.type === "pointerdown";
    if (!isFading) {
      if (down) {
        isDownRef.current = true;
        setTouchPointSpring.start({
          touchPointScale: 0.5,
        });
      } else if (isDownRef.current) {
        onTap();
        isDownRef.current = false;
        setTouchPointSpring.start({
          touchPointScale: 0.65,
        });
        gsap.fromTo(
          touchPointMat.uniforms.uTime,
          { value: 0 },
          { value: 2, duration: 1.7, ease: "back.out(.7)" }
        );
      }
    }
  };

  const handleTouchPointOut = () => {
    setTouchPointSpring.start({
      touchPointScale: 0.65,
    });
  };

  useEffect(() => {
    if (isScenePlaced) {
      if (/Global/gi.test(view)) {
        setVisibility(true);
        apiTouchPointAndStem.start({
          config: { ...touchPointSptingConfig, clamp: false },
          delay: 500,
          scale: 1,
        });
      } else {
        if (name === view) {
          setVisibility(true);
          apiTouchPointAndStem.start({
            config: { ...touchPointSptingConfig, clamp: false },
            delay: 500,
            scale: 1,
          });
        } else {
          apiTouchPointAndStem.start({
            config: { ...touchPointSptingConfig, clamp: true },
            delay: 500,
            scale: -0.0001,
            onRest: () => {
              setVisibility(false);
            },
          });
        }
      }

      if (name in visitedViews) {
        gsap.to(touchPointMat.uniforms.uBracket, {
          value: 0.25,
          duration: 0.5,
          ease: "back.out(.7)",
          overwrite: true,
        });
      }
    }
  }, [isScenePlaced, view]);

  return (
    <a.group
      position={position}
      rotation={rotation}
      visible={visibility}
      scale={touchPointAndStem.scale}
    >
      <group renderOrder={2} depthWrite={false} rotation={[0, 0, Math.PI]}>
        <Line
          points={[
            [0, 0, 0],
            [0, stemLength, 0],
          ]}
          color={touchPointColor.clone().convertSRGBToLinear()}
          lineWidth={0.9}
          transparent={true}
          depthWrite={false}
        />
      </group>
      <a.group
        renderOrder={3}
        ref={touchPointRef}
        scale={touchPointScale}
        onPointerOut={handleTouchPointOut}
        onPointerDown={handleTouchPointTap}
        onPointerUp={handleTouchPointTap}
      >
        <Circle material={touchPointMat} args={[0.5, 8, 0, Math.PI * 2]} />
      </a.group>
    </a.group>
  );
};

export default memo(TouchPoint);
