// Gsap
import gsap from "gsap";

const createR3FXR8Pipeline = (
  gl,
  XR8,
  scene,
  camera,
  R3Fctx,
  onError,
  onPipelineReady
) => {
  let onPipelineReadyCalled = false;

  const R3FXR8Pipeline = {
    name: "R3FXR8Pipeline",

    onStart: ({ GLctx }) => {
      if (GLctx !== R3Fctx) {
        throw new Error("Contexts Do Not Match");
      }

      if (gl.XR8Started) {
        return;
      }

      XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
      });

      gl.XR8Started = true;
    },

    onUpdate: ({ processCpuResult }) => {
      if (!processCpuResult.reality) {
        return;
      }

      const { intrinsics, rotation, position, trackingStatus } =
        processCpuResult.reality;

      if (position) {
        gsap.to(camera.position, {
          x: position.x,
          y: position.y,
          z: position.z,
          duration: 0.2,
          overwrite: true,
        });
      }

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }

      if (intrinsics) {
        camera.projectionMatrix.elements = intrinsics;
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      }

      camera.matrixWorldNeedsUpdate = true;

      if (
        !onPipelineReadyCalled &&
        (trackingStatus === "NORMAL" || trackingStatus === "LIMITED")
      ) {
        onPipelineReady();
        onPipelineReadyCalled = true;
      }
    },

    onRender: () => {
      if (!gl) {
        return;
      }

      gl.clearDepth();
      gl.render(scene, camera);
    },

    onDetach: () => {
      gl.XR8Started = false;
    },

    onException: (error) => {
      onError(error);
    },
  };

  return R3FXR8Pipeline;
};

export { createR3FXR8Pipeline };
