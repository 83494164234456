// React
import { useRef, useMemo, memo, useEffect } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { RawShaderMaterial } from "three";

// React Three Fiber
import { useThree, useFrame } from "@react-three/fiber";

// Gsap
import gsap from "gsap";

const IonThrusterPatricleSystem = ({ count = 5000 }) => {
  // Refs
  const particleSystemRef = useRef();

  // Global State
  const isImmersive = useGlobalState((state) => state.isImmersive);

  // Three
  const { gl } = useThree();

  const { positions, scales, colors } = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);
    const scales = new Float32Array(count);
    for (let i = 0; i < count * 3; i++) {
      positions[i] = Math.random() * 200.0;
      scales[i] = Math.random() * 1000.0;
      colors[i] = Math.random();
    }
    return { positions, scales, colors };
  }, [count]);

  // Shader Attributes
  const uniforms = {
    uTime: { value: 0 },
    uSize: { value: 2 * gl.getPixelRatio() },
    uAlpha: { value: 0 },
  };

  const vertexShader = `
        precision highp float;
        uniform mat4 projectionMatrix;
        uniform mat4 modelMatrix;
        uniform mat4 viewMatrix;
        uniform float uTime;
        uniform float uSize;
        
        attribute float scale;
        attribute vec3 position;
        attribute vec3 color;
    
        varying vec3 vPos;
        varying vec3 vColor;
        varying float vScale;
    
        void main() {         
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectionPosition = projectionMatrix * viewPosition;
            gl_Position = projectionPosition;
            gl_PointSize = uSize * scale * .3;
            gl_PointSize *= (1.0 / - viewPosition.z);
            vPos = position;
            vColor = color;
            vScale = scale;
        }
    `;

  const fragmentShader = `
        precision highp float;
        uniform float uTime;
        uniform float uAlpha;
        varying vec3 vPos;
        varying vec3 vColor;
        varying float vScale;


        mat2 rotate(float angle) {
            float angleSin = sin(angle);
            float angleCos = cos(angle);
            return mat2(angleCos, -angleSin, angleSin, angleCos);
        }

        float createStar(vec2 uv, float flareAtten) {
            float dist = length(uv);
            float m = 0.000001 / dist; 
            float flare = max(0.0, 1.0 - abs(uv.x * uv.y * 500.0));
            m += flare * flareAtten;
            uv *= rotate(3.1415 / 4.0);
            flare = max(0.0, 1.0 - abs(uv.x * uv.y * 500.0));
            m += flare * 0.3 * flareAtten;
            m *= smoothstep(1.0, 0.01, dist);
            return m;
        }
        
        void main() {
            float strength = distance(gl_PointCoord, vec2(0.5));
            strength = 1.0 - strength;
            strength = pow(strength, 8.5);
            strength *= 3.0; 
            float star = createStar(gl_PointCoord.xy - 0.5, 1.5);
            vec3 baseColor = vec3(0.5, 0.9, 1.0);
            vec3 variedColor = mix(baseColor, vColor, 0.4);
            variedColor += star;
            gl_FragColor = vec4(variedColor, strength * sin(uTime + vScale) * uAlpha) ;
        }
    `;

  // Shader Material
  const { ionThrusterParticleMat } = useMemo(() => {
    const ionThrusterParticleMat = new RawShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: uniforms,
      transparent: true,
      depthWrite: false,
    });
    return { ionThrusterParticleMat };
  }, []);

  // onFrame
  useFrame(({ clock }) => {
    particleSystemRef.current.material.uniforms.uTime.value =
      clock.getElapsedTime();
  });

  useEffect(() => {
    if (isImmersive) {
      gsap.to(particleSystemRef.current.material.uniforms.uAlpha, {
        value: 1,
        duration: 1.3,
        ease: "power3.inOut",
        overwrite: true,
      });
    } else {
      gsap.to(particleSystemRef.current.material.uniforms.uAlpha, {
        value: 0,
        duration: 1.3,
        ease: "power3.inOut",
        overwrite: true,
      });
    }
  }, [isImmersive]);

  return (
    <group renderOrder={3}>
      <points
        ref={particleSystemRef}
        material={ionThrusterParticleMat}
        position={[-100, -100, -100]}
        scale={1}
      >
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            array={positions}
            count={positions.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-scale"
            array={scales}
            count={scales.length}
            itemSize={1}
          />
          <bufferAttribute
            attach="attributes-color"
            array={colors}
            count={colors.length / 3}
            itemSize={3}
          />
        </bufferGeometry>
      </points>
    </group>
  );
};

export default memo(IonThrusterPatricleSystem);
