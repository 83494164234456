// React
import { memo } from "react";

// Drei
import { Instances, Instance } from "@react-three/drei";

// Data
import { houseData } from "./HouseData";

const House = ({ nodes, materials }) => {
  return (
    <Instances material={materials.house_mat} geometry={nodes.house.geometry}>
      {houseData.map(({ id, scale, position, rotation }) => {
        return (
          <Instance
            key={id}
            scale={scale}
            position={position}
            rotation={rotation}
          />
        );
      })}
    </Instances>
  );
};

export default memo(House);
