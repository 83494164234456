const houseData = [
  {
    id: 0,
    scale: 0.04,
    position: [0.29, 3.34, 1.515],
    rotation: [0.96, 3.82, 0.23],
  },
  {
    id: 1,
    scale: 0.03,
    position: [0.69, 3.16, 1.53],
    rotation: [1.0, 3.31, 0.33],
  },
  {
    id: 2,
    scale: 0.03,
    position: [0.5, 3.48, 1.31],
    rotation: [0.78, 3.31, 0.28],
  },
];

export { houseData };
