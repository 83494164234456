// React
import { useRef, useEffect, memo } from "react";

// State
import { useGlobalState } from "../../../state/useGlobalState";

// Styled Components
import styled from "styled-components";

// GSAP
import gsap from "gsap";

// React Spring
import { useSpring, a } from "@react-spring/web";

// Styles
import { PageContainer } from "../../../styles/Containers";
import { MarginPaddingNone } from "../../../styles/Attributes";

const Landing = () => {
  // Refs
  const baseLeafRef = useRef();
  const leavesRef = useRef();
  const heartRef = useRef();
  const globeRef = useRef();
  const astronautRef = useRef();
  const handHeartRef = useRef();
  const footerRef = useRef();
  const cloverRef = useRef();
  const headerRef = useRef();
  const introRef = useRef();
  const launchRef = useRef();
  const landingContainerRef = useRef();

  // Global State
  const hudPhase = useGlobalState((state) => state.hudPhase);
  const setHudPhase = useGlobalState((state) => state.setHudPhase);
  const playVO = useGlobalState((state) => state.playVO);
  const playBackground = useGlobalState((state) => state.playBackground);

  // Animations
  const [{ btnScale }, setBtnSpring] = useSpring(() => ({
    config: { mass: 1, tension: 300, friction: 12, precision: 0.001 },
    btnScale: 1,
  }));

  const tl = gsap.timeline();

  // Functions
  const handleNextPhase = () => {
    tl.to(
      [
        launchRef.current,
        introRef.current,
        headerRef.current,
        cloverRef.current,
      ],
      {
        y: -10,
        opacity: 0,
        ease: "power3.inOut",
        overwrite: true,
        stagger: {
          each: 0.2,
        },
      }
    );

    gsap.to(handHeartRef.current, {
      x: 100,
      ease: "power3.inOut",
      overwrite: true,
    });
    gsap.to(globeRef.current, {
      x: -100,
      ease: "power3.inOut",
      overwrite: true,
    });
    gsap.to(astronautRef.current, {
      x: 200,
      ease: "power3.inOut",
      overwrite: true,
    });
    gsap.to(heartRef.current, {
      x: -200,
      ease: "power3.inOut",
      overwrite: true,
    });

    gsap.to(landingContainerRef.current, {
      opacity: 0,
      ease: "power3.inOut",
      delay: 0.9,
      onComplete: () => {
        setHudPhase("Instruct");
        gsap.to(landingContainerRef.current, {
          display: "none",
          duration: 0.00001,
        });
      },
    });
  };

  const handleMount = () => {
    tl.to([baseLeafRef.current, leavesRef.current, footerRef.current], {
      opacity: 1,
      ease: "power3.inOut",
      stagger: {
        each: 0.2,
      },
    });
    gsap.to(
      [
        heartRef.current,
        astronautRef.current,
        globeRef.current,
        handHeartRef.current,
      ],
      {
        x: 0,
        ease: "power3.inOut",
        delay: 0.5,
        overwrite: true,
        duration: 1,
      }
    );

    tl.fromTo(
      [
        cloverRef.current,
        headerRef.current,
        introRef.current,
        launchRef.current,
      ],
      { opacity: 0, y: -10 },
      {
        y: 0,
        opacity: 1,
        delay: 0.5,
        ease: "power3.inOut",
        overwrite: true,
        stagger: {
          each: 0.2,
        },
      }
    );
    playVO("Intro");
    playBackground();
  };

  const scaleUpButton = () => {
    setBtnSpring.start({
      btnScale: 1,
    });
  };

  const handlePointerDown = (e) => {
    e.stopPropagation();
    setBtnSpring.start({
      btnScale: 0.85,
    });
  };

  const handlePointerUp = () => {
    scaleUpButton();
    handleNextPhase();
  };

  useEffect(() => {
    if (/Landing/gi.test(hudPhase)) {
      handleMount();
    }

    return () => {
      tl.kill();
      gsap.killTweensOf([
        baseLeafRef.current,
        leavesRef.current,
        heartRef.current,
        globeRef.current,
        astronautRef.current,
        handHeartRef.current,
        footerRef.current,
        cloverRef.current,
        headerRef.current,
        introRef.current,
        launchRef.current,
        landingContainerRef.current,
      ]);
    };
  }, [hudPhase]);

  return (
    <LandingContainer ref={landingContainerRef}>
      <div ref={baseLeafRef} className="base-leaf">
        <img
          draggable="false"
          src="/ui/1-landing/base_leaf.svg"
          alt="base_leaf"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={leavesRef} className="leaves">
        <img
          draggable="false"
          src="/ui/1-landing/leaves.svg"
          alt="leaves"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={heartRef} className="heart">
        <img
          draggable="false"
          src="/ui/1-landing/heart.svg"
          alt="heart"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={globeRef} className="yellow-globe">
        <img
          draggable="false"
          src="/ui/1-landing/yellow_globe.svg"
          alt="yellow-globe"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={astronautRef} className="astronaut">
        <img
          draggable="false"
          src="/ui/1-landing/astronaut.svg"
          alt="astronaut"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={handHeartRef} className="handheart">
        <img
          draggable="false"
          src="/ui/1-landing/handheart.svg"
          alt="handheart"
          width="100%"
          height="100%"
        />
      </div>

      <div ref={footerRef} className="footer">
        <div className="hughsnet">
          <img
            draggable="false"
            src="/ui/1-landing/hughsnet.svg"
            alt="hughsnet"
            width="100%"
            height="100%"
          />
        </div>
        <p className="copy">
          © 1902-2022 National 4-H Council. <br />
          All Rights Reserved.
        </p>
      </div>

      <div className="center">
        <div ref={cloverRef} className="clover">
          <img
            draggable="false"
            src="/ui/1-landing/clover.svg"
            alt="4h-clover"
            width="100%"
            height="100%"
          />
        </div>
        <h1 ref={headerRef} className="header">
          HOW DOES <br />
          SATELLITE <br />
          INTERNET WORK?
        </h1>

        <p ref={introRef} className="intro">
          Have you ever wondered how satellites in space help us communicate
          across vast distances? Explore the fundamentals of how satellite
          internet communications work in this interactive, augmented reality
          (AR) experience.
        </p>

        <a.button
          ref={launchRef}
          style={{ scale: btnScale }}
          className="launch-ar"
          onPointerOut={scaleUpButton}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
        >
          <img
            draggable="false"
            src="/ui/1-landing/launch-ar.svg"
            alt="launch-ar"
            width="100%"
            height="100%"
          />
        </a.button>
      </div>
    </LandingContainer>
  );
};

export default memo(Landing);

const LandingContainer = styled(PageContainer)`
  background-color: #339966;
  .base-leaf {
    position: fixed;
    width: 101%;
    opacity: 0;
  }
  .leaves {
    position: fixed;
    width: 101%;
    opacity: 0;
  }
  .heart {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate(-200px, 0px);
    width: 8.5rem;
  }
  .yellow-globe {
    position: fixed;
    left: 0;
    bottom: 0;
    transform: translate(-100px, 0px);
    margin-bottom: 3rem;
    width: 6rem;
  }

  .astronaut {
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(200px, 0px);
    margin-top: 6rem;
    width: 9rem;
  }

  .handheart {
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translate(100px, 0px);
    width: 6rem;
  }

  .footer {
    ${MarginPaddingNone};
    opacity: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    .hughsnet {
      ${MarginPaddingNone};
      margin-bottom: 0.2rem;
    }
    .copy {
      ${MarginPaddingNone};
      font-family: "GilroySemiBold";
      letter-spacing: 0rem;
      font-size: 0.7rem;
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
      text-align: center;
      color: white;
    }
  }

  .center {
    ${MarginPaddingNone};
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    width: 80%;
    max-width: 600px;
    margin-bottom: 2.5rem;

    .clover {
      ${MarginPaddingNone};
      opacity: 0;
      width: 6rem;
    }

    .header {
      ${MarginPaddingNone};
      opacity: 0;
      font-family: "GilroySemiBold";
      letter-spacing: 0rem;
      text-align: center;
      margin-top: 1rem;
    }

    .intro {
      ${MarginPaddingNone};
      opacity: 0;
      font-family: "GilroyRegular";
      text-align: center;
      margin-top: 1rem;
    }

    .launch-ar {
      ${MarginPaddingNone};
      opacity: 0;
      border: none;
      background: none;
      width: 12.5rem;
      margin-top: 1.2rem;
    }
  }
`;
