// React
import { memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { Color } from "three";

// Components
import TouchPoint from "./TouchPoint";

const TouhPoints = ({ viewType, touchPointAtlas }) => {
  // Global State
  const playVO = useGlobalState((state) => state.playVO);
  const setCurrentKey = useGlobalState((state) => state.setCurrentKey);
  const view = useGlobalState((state) => state.view);
  const setView = useGlobalState((state) => state.setView);
  const setIsFading = useGlobalState((state) => state.setIsFading);
  const isGlobalMute = useGlobalState((state) => state.isGlobalMute);

  // onTap
  const onSatelliteTap = () => {
    if (!/Satellite/gi.test(view)) {
      setView("Satellite");
      playVO("Satellite");
      setCurrentKey("Satellite");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onGateWayTap = () => {
    if (!/Gateway/gi.test(view)) {
      setView("Gateway");
      playVO("Gateway");
      setCurrentKey("Gateway");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onHouseTap = () => {
    if (!/House/gi.test(view)) {
      setView("House");
      playVO("House");
      setCurrentKey("House");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onSolarArrayTap = () => {
    if (!/SolarArray/gi.test(view)) {
      setView("SolarArray");
      playVO("SolarArray");
      setCurrentKey("SolarArray");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onBusTap = () => {
    if (!/Bus/gi.test(view)) {
      setView("Bus");
      playVO("Bus");
      setCurrentKey("Bus");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onThrustersTap = () => {
    if (!/Thrusters/gi.test(view)) {
      setView("Thrusters");
      playVO("Thrusters");
      setCurrentKey("Thrusters");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onReflectorsTap = () => {
    if (!/Reflectors/gi.test(view)) {
      setView("Reflectors");
      playVO("Reflectors");
      setCurrentKey("Reflectors");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };
  const onStarTrackerTap = () => {
    if (!/StarTracker/gi.test(view)) {
      setView("StarTracker");
      playVO("StarTracker");
      setCurrentKey("StarTracker");
      if (!isGlobalMute) {
        setIsFading(true);
      }
    }
  };

  // Data
  const touchPointData = /global/gi.test(viewType)
    ? [
        {
          id: 0,
          name: "Gateway",
          onTap: onGateWayTap,
          position: [1.61, 3.91, 1.2],
          rotation: [0, 0, 5.79],
          stemLength: 0.6,
          atlasShiftX: -0.125,
          atlasShiftY: 0.625,
          touchPointColor: new Color(1.0, 1.0, 1.0),
        },
        {
          id: 1,
          name: "House",
          onTap: onHouseTap,
          position: [-0.04, 3.96, 1.74],
          rotation: [0.27, 0.0, 0.46],
          stemLength: 0.6,
          atlasShiftX: 0.125,
          atlasShiftY: 0.375,
          touchPointColor: new Color(1.0, 1.0, 1.0),
        },
      ]
    : [
        {
          id: 2,
          name: "Satellite",
          onTap: onSatelliteTap,
          position: [-1.9, 0.8, 0.62],
          rotation: [0, 0, 0.5],
          stemLength: 0.6,
          atlasShiftX: 0.125,
          atlasShiftY: 0.625,
          touchPointColor: new Color(1.0, 1.0, 1.0),
        },
        {
          id: 3,
          name: "SolarArray",
          onTap: onSolarArrayTap,
          position: [1.56, 0.55, -0.55],
          rotation: [0, 0, 5.67],
          stemLength: 0.6,
          atlasShiftX: -0.125,
          atlasShiftY: 0.375,
          touchPointColor: new Color(
            0.937254901960784,
            0.701960784313725,
            0.596078431372549
          ),
        },
        {
          id: 4,
          name: "Bus",
          onTap: onBusTap,
          position: [0.48, -0.5, -0.34],
          rotation: [0, 0, 4.0],
          stemLength: 0.6,
          atlasShiftX: 0.125,
          atlasShiftY: 0.125,
          touchPointColor: new Color(0.2, 0.6, 0.4),
        },
        {
          id: 5,
          name: "Thrusters",
          onTap: onThrustersTap,
          position: [0.74, 0.51, 0.14],
          rotation: [0, 0, 5.67],
          stemLength: 0.6,
          atlasShiftX: -0.125,
          atlasShiftY: 0.125,
          touchPointColor: new Color(
            0.705882352941176,
            0.862745098039216,
            0.894117647058824
          ),
        },
        {
          id: 6,
          name: "Reflectors",
          onTap: onReflectorsTap,
          position: [-0.48, 1.07, 0.4],
          rotation: [0, 0, 0.44],
          stemLength: 0.6,
          atlasShiftX: 0.125,
          atlasShiftY: -0.125,
          touchPointColor: new Color(
            0.96078431372549,
            0.262745098039216,
            0.349019607843137
          ),
        },
        {
          id: 7,
          name: "StarTracker",
          onTap: onStarTrackerTap,
          position: [-0.91, -0.07, -0.37],
          rotation: [0, 0, 1.59],
          stemLength: 0.6,
          atlasShiftX: -0.125,
          atlasShiftY: -0.125,
          touchPointColor: new Color(
            0.992156862745098,
            0.76078431372549,
            0.090196078431373
          ),
        },
      ];

  return touchPointData.map(
    ({
      id,
      name,
      onTap,
      position,
      rotation,
      stemLength,
      atlasShiftX,
      atlasShiftY,
      touchPointColor,
    }) => {
      return (
        <TouchPoint
          key={id}
          name={name}
          onTap={onTap}
          position={position}
          rotation={rotation}
          stemLength={stemLength}
          atlasShiftX={atlasShiftX}
          atlasShiftY={atlasShiftY}
          touchPointColor={touchPointColor}
          touchPointAtlas={touchPointAtlas}
        />
      );
    }
  );
};

export default memo(TouhPoints);
