// Zustand
import create from "zustand";

// Modules
import { XR8Module } from "./modules/XR8Module";
import { R3FModule } from "./modules/R3FModule";
import { StyleModule } from "./modules/StyleModule";
import { AudioModule } from "./modules/AudioModule";
import { CaptureModule } from "./modules/CaptureModule";
import { InteractionModule } from "./modules/InteractionModule";

// Init Values
const hudPhaseIndex = 0;
const hudPhases = ["Loader", "Landing", "Instruct", "ARLaunch"];
const hudPhase = hudPhases[hudPhaseIndex];

const viewIndex = 0;
const views = [
  "Global",
  "Satellite",
  "Gateway",
  "House",
  "Bus",
  "Thrusters",
  "Reflectors",
  "SolarArray",
  "StarTracker",
];
const view = views[viewIndex];

// STORE
const useGlobalState = create((set, get) => ({
  // R3F
  ...R3FModule({ set, get }),

  // XR8
  ...XR8Module({ set, get }),

  // Style
  ...StyleModule({ set, get }),

  // Audio
  ...AudioModule({ set, get }),

  // Capture
  ...CaptureModule({ set, get }),

  // Interaction
  ...InteractionModule({ set, get }),

  // hudPhases
  hudPhase,
  hudPhases,
  hudPhaseIndex,
  setHudPhase: (hudPhase) => set({ hudPhase }),

  visitedViews: {},

  // Views
  view,
  views,
  viewIndex,
  setView: (view) => {
    if (view in get().visitedViews === false) {
      get().visitedViews[view] = view;
    }
    set({ view });
  },
}));

export { useGlobalState };
