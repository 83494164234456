// React
import { useLayoutEffect, memo } from "react";

// React Three Fiber
import { useThree } from "@react-three/fiber";

// Custom Pipeline
import { createR3FXR8Pipeline } from "./XR8Pipeline";

const XR8Controls = ({ XR8, onPipelineReady, iframeInnerPipelineModule }) => {
  const { gl, scene, camera } = useThree();

  const R3Fctx = gl.getContext();

  const onError = (error) => {
    throw error;
  };

  useLayoutEffect(() => {
    if (!XR8) {
      return;
    }

    XR8.clearCameraPipelineModules();

    XR8.addCameraPipelineModules([
      XR8.GlTextureRenderer.pipelineModule(),
      XR8.XrController.pipelineModule(),
      XR8.CanvasScreenshot.pipelineModule(),
      iframeInnerPipelineModule,
      createR3FXR8Pipeline(
        gl,
        XR8,
        scene,
        camera,
        R3Fctx,
        onError,
        onPipelineReady
      ),
    ]);

    XR8.run({ canvas: gl.domElement });

    return () => {
      gl.autoClear = true;
      XR8.stop();
    };
  }, []);

  return null;
};

export default memo(XR8Controls);
