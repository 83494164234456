// React
import { memo } from "react";

// Drei
import { Environment as EnvironmentMap } from "@react-three/drei";

const Environment = () => {
  return <EnvironmentMap files={"sunset.hdr"} path={"/envMaps/"} />;
};

export default memo(Environment);
