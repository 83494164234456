// Styled Components
import styled from "styled-components";
import { MarginPaddingNone } from "./Attributes";

const PageContainer = styled.div`
  ${MarginPaddingNone}
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PageContainerBG = styled.div`
  ${MarginPaddingNone}
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
`;

const SceneContainer = styled.div`
  ${MarginPaddingNone}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export { PageContainer, PageContainerBG, SceneContainer };
