// React
import { useRef, useEffect, useMemo, memo } from "react";

// Global State
import { useGlobalState } from "../../../../state/useGlobalState";

// Three
import { MeshBasicMaterial, Color } from "three";

// GSAP
import gsap from "gsap";

const Satellite = ({ nodes, materials }) => {
  // Refs
  const group = useRef();
  const solarArrayRef = useRef();
  const busMatRef = useRef();
  const thrustersMatRef = useRef();
  const reflectorsMatRef = useRef();
  const solarArrayMatRef = useRef();
  const starTrackerMatRef = useRef();

  // Global State
  const view = useGlobalState((state) => state.view);

  useEffect(() => {
    if (/SolarArray/gi.test(view)) {
      gsap.to(solarArrayMatRef.current.material, {
        opacity: 1,
        ease: "power3.inOut",
        delay: 1,
        duration: 1,
        overwrite: true,
      });
      gsap.to(solarArrayRef.current.rotation, {
        x: 0.5,
        ease: "power1.inOut",
        yoyo: true,
        repeat: -1,
        duration: 4,
        overwrite: true,
      });
    } else {
      gsap.to(solarArrayRef.current.rotation, {
        x: -0.7,
        ease: "power3.inOut",
        duration: 2,
        overwrite: true,
      });
      gsap.to(solarArrayMatRef.current.material, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
    }

    if (/Bus/gi.test(view)) {
      gsap.to(busMatRef.current.material, {
        opacity: 1,
        ease: "power3.inOut",
        delay: 1,
        duration: 1,
        overwrite: true,
      });
    } else {
      gsap.to(busMatRef.current.material, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
    }

    if (/Thrusters/gi.test(view)) {
      gsap.to(thrustersMatRef.current.material, {
        opacity: 1,
        ease: "power3.inOut",
        delay: 1,
        duration: 1,
        overwrite: true,
      });
      gsap.to(materials.panels_mat, {
        emissiveIntensity: 2,
        ease: "power1.inOut",
        duration: 2,
        delay: 1,
        overwrite: true,
      });
    } else {
      gsap.to(thrustersMatRef.current.material, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
      gsap.to(materials.panels_mat, {
        emissiveIntensity: 0,
        ease: "power1.inOut",
        duration: 1,
        overwrite: true,
      });
    }

    if (/Reflectors/gi.test(view)) {
      gsap.to(reflectorsMatRef.current.material, {
        opacity: 1,
        ease: "power3.inOut",
        delay: 1,
        duration: 1,
        overwrite: true,
      });
    } else {
      gsap.to(reflectorsMatRef.current.material, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
    }

    if (/StarTracker/gi.test(view)) {
      gsap.to(starTrackerMatRef.current.material, {
        opacity: 1,
        ease: "power3.inOut",
        delay: 1,
        duration: 1,
        overwrite: true,
      });
    } else {
      gsap.to(starTrackerMatRef.current.material, {
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "power3.inOut",
        overwrite: true,
      });
    }
  }, [view]);

  useEffect(() => {
    if (materials) {
      materials.panels_mat.emissive = new Color(1, 1, 1);
    }
  }, [materials]);

  const {
    busHMat,
    thrustersHMat,
    reflectorsHMat,
    solarArrayHMat,
    starTrackerHMat,
  } = useMemo(() => {
    const busHMat = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: new Color(0.2, 0.6, 0.4).convertSRGBToLinear(),
      depthWrite: false,
    });

    const thrustersHMat = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: new Color(
        0.605882352941176,
        0.762745098039216,
        0.794117647058824
      ).convertSRGBToLinear(),
      depthWrite: false,
    });

    const reflectorsHMat = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: new Color(
        0.96078431372549,
        0.262745098039216,
        0.349019607843137
      ).convertSRGBToLinear(),
      depthWrite: false,
    });

    const solarArrayHMat = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: new Color(
        0.937254901960784,
        0.701960784313725,
        0.596078431372549
      ).convertSRGBToLinear(),
      depthWrite: false,
    });

    const starTrackerHMat = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      color: new Color(
        0.992156862745098,
        0.76078431372549,
        0.090196078431373
      ).convertSRGBToLinear(),
      depthWrite: false,
    });
    return {
      busHMat,
      thrustersHMat,
      reflectorsHMat,
      solarArrayHMat,
      starTrackerHMat,
    };
  }, []);

  const handleStopProp = (e) => {
    e.stopPropagation();
  };

  return (
    <group
      ref={group}
      dispose={null}
      scale={1.5}
      rotation={[-0.2, 0.4, 0]}
      renderOrder={3}
      onPointerDown={handleStopProp}
      oPointerUp={handleStopProp}
    >
      <mesh
        ref={busMatRef}
        geometry={nodes.co_Bus.geometry}
        material={busHMat}
      />
      <mesh
        ref={starTrackerMatRef}
        geometry={nodes.co_StarTracker.geometry}
        material={starTrackerHMat}
      />
      <mesh
        ref={thrustersMatRef}
        geometry={nodes.co_Thrusters.geometry}
        material={thrustersHMat}
      />
      <mesh
        ref={reflectorsMatRef}
        geometry={nodes.co_Dishes.geometry}
        material={reflectorsHMat}
      />
      <mesh
        geometry={nodes.satelite_1.geometry}
        material={materials.panels_mat}
      />
      <mesh geometry={nodes.satelite_2.geometry} material={materials.sat_mat} />
      <group ref={solarArrayRef} rotation={[-1, 0, 0]} renderOrder={3}>
        <mesh
          ref={solarArrayMatRef}
          geometry={nodes.co_Panels.geometry}
          material={solarArrayHMat}
        />
        <mesh
          geometry={nodes.panels.geometry}
          material={materials.panels_mat}
        />
      </group>
    </group>
  );
};

export default memo(Satellite);
