// React
import { Fragment, useMemo, memo } from "react";

// XR8
import { useXR8 } from "../components/1-XR8/useXR8";

// Components
import HUD from "../components/2-UI/0-HUD/HUD";
import R3FXR8Canvas from "../components/0-R3F/R3FXR8Canvas";

const R3FXR8Page = () => {
  // Constants
  const { XR8APIKEY } = useMemo(() => {
    const XR8APIKEY = process.env.REACT_APP_XR8_API_KEY;
    return { XR8APIKEY };
  }, []);

  // XR8
  const { XR8, iframeInnerPipelineModule } = useXR8(XR8APIKEY);

  return (
    <Fragment>
      <R3FXR8Canvas
        XR8={XR8}
        iframeInnerPipelineModule={iframeInnerPipelineModule}
      />
      <HUD />
    </Fragment>
  );
};

export default memo(R3FXR8Page);
