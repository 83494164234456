const gatewayData = [
  {
    id: 0,
    scale: 1,
    position: [0.01, 4.71, -0.12],
    rotation: [Math.PI * 2, 0.42, 0],
  },
  {
    id: 1,
    scale: 0.39,
    rotation: [5.38, 0, 0],
    position: [-32.36, -3.5, 5.34],
  },
  {
    id: 2,
    scale: 0.19,
    rotation: [5.9, 0.32, 0],
    position: [-25.03, -0.7, -2.19],
  },
  {
    id: 3,
    scale: 0.16,
    rotation: [5.53, 0.32, 0],
    position: [-18.15, 0.19, 11.04],
  },
];

export { gatewayData };
